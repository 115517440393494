import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './style.scss';

class ServicePageForm extends Component {
  constructor(props) {   
    super(props);
  }

  componentDidMount() {
    const script = document.createElement('script');
    
    script.src = 'https://nordicsecurityalliance.activehosted.com/f/embed.php?id=9';
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    return <Container fluid className="p-t-100 p-b-100 bg-gray">
      <Container>
        <Row>
          <Col sm={{offset: 2, span: 8}}>
            <h3>Etkö löydä etsimääsi tai sinulla on kysyttävää? Ota meihin yhteyttä!</h3>

            <div className="_form_9"></div>
          </Col>
        </Row>
      </Container>
    </Container>
  }
};

export default ServicePageForm