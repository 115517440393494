import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class FAQ extends Component { 
  render() {
    return (
      <Container className="p-t-50">
        <Row>
          <Col sm={{ span: 8, offset: 2 }}>
            <h3>Meiltä usein kysyttyä:</h3>

            <h4>Mikä on vartiointipalveluidemme hintataso?</h4>
            <p>Hinta riippuu täysin asiakkaan tarpeesta, toimialasta, tehtävän vaativuudesta sekä henkilöstön koulutusvaatimuksista. Parhaiten pystymme vastaamaan kun olemme kartoittaneet tilanteesi.</p>

            <h4>Saako teiltä piiri- ja hälytysvartiointia?</h4>
            <p>Valitettavasti emme itse tuota piiri- ja hälytysvartiointia tällä hetkellä. Pystymme kuitenkin tarjoamaan piiri- ja hälytysvartiointia alihankintana, laajan yhteistyöverkostomme kautta.</p>

            <h4>Mikä on vartijoiden palkkaus?</h4>
            <p>Noudatamme palkkauksessa ja työehdoissa vartiointialan työehtosopimusta. Maksamme työntekijällemme vähintään TES3 tason palkkaa.</p>

            <h4>Millainen on vartijoidenne koulutus?</h4>
            <p>Vartijamme ovat suorittaneet vähintään sisäministeriön asetuksen vaatimukset täyttävän vartijan peruskoulutuksen sekä vartijan voimankäyttöön liittyvän fyysisen voimankäytön perusteet. Lisäksi järjestämme vartijoillemme säännöllistä voimankäyttökoulutusta, voimankäyttövälineisiin liittyvää lisäkoulutusta, skenaario harjoituksia sekä palo-, pelastus- ja ensiapukoulutusta. Lisäksi annamme työntekijöillemme eri työtehtävien vaatimaa lisäkoulutusta sekä kattavan perehdytyksen työtehtäviin ja erilaisiin asiakapalvelu tilanteisiin.</p>

            <h4>Kuinka nopeasti vartiointipalvelumme on saatavilla?</h4>
            <p>Tilanteet saattavat välillä yllättää, jolloin vartiointipalvelun saamisella voi olla kiire. Yhteydenoton jälkeen aluevastaavamme tarkistaa alueellisen resurssin ja ilmoittaa asiakkaalle tilanteen. Teemme aina parhaamme, jotta pystyisimme vaastaamaan myös yllättäviin ja kiireellisiin tilanteisiin.</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FAQ;
