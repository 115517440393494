import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ServiceHeader from '../../components/ServiceHeader';
import FAQ from '../../components/FAQService3';
import ServicePage23WhyChoose from '../../components/ServicePage23WhyChoose';
import ServicePageForm from '../../components/ServicePageForm';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_3 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner> 
            <h2>Konsultointi ja suunnittelu</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50 text-center" id="content">
            <Row>
                <Col sm={{ span: 8, offset: 2}}>
                    <h1>ASIANTUNTIJAPALVELUT</h1>
                    <h3>AMMATTIMAISTA TURVALLISUUDEN KOKONAISHALLINTAA</h3>
                    <p>
                      Tarjoamme monipuolisia turvallisuuden asiantuntijapalveluita yrityksille ja organisaatioille. Palveluihimme kuuluvat turvallisuusjohtaminen, työturvallisuuden hallinta, turvallisuus- ja pelastussuunnittelu sekä riskienarviointi ja -hallinta. Lisäksi tarjoamme erilaisia räätälöityjä fyysisen turvallisuuden ratkaisuja. Sitoudumme kehittämään ja vahvistamaan asiakkaidemme turvallisuuskulttuuria tarjoamalla luotettavia ja innovatiivisia ratkaisuja.
                    </p>

                    <h4>Miksi turvallisuuden asiantuntijapalvelut ovat tärkeitä?</h4>
                    <p className="p-b-50">
                      Turvallisuuden asiantuntijapalveluilla varmistat, että yrityksesi, organisaatiosi tai yhteisösi turvallisuus ja erilaisiin riskeihin varautuminen on kunnossa. Valitsemalla turvallisuuden ammattilaisen tekemään turvallisuussuunnittelun, turvallisuusjohtamisen tai muun turvallisuuden asiantuntijatehtävän puolestasi, vapautat oman aikasi omilla vahvuusalueillasi työskentelemiseen. Tällä varmistat, että henkilöstösi ja liiketoimintasi voivat hyvin ja ovat turvassa.
                    </p>

                    <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </Col>
            </Row>
          </Container>

          <Container fluid className="p-b-25 p-t-25 bg-gray">
            <Row>
              <Col>
                <Container className="bg-white p-b-25 p-t-25 text-center">
                  <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                  <NewReferences references={['noho', 'finlandia', 'oldIrish', 'jokerit', 'rajupaja', 'allas', 'vanha', 'jt']} />
                </Container>
              </Col>
            </Row>
          </Container>

          <Container className="p-t-25">
            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <h3>Palvelumme - Suunnittelusta toteutukseen</h3>
                <p>Asiantuntijapalvelumme on suunniteltu tukemaan asiakkaidemme päivittäistoimintaa, sekä parantamaan ja kehittämään turvallisuuskulttuuria ja riskeihin varautumista asiakasorganisaatiossa. Asiantuntevalla suunnittelulla ja turvallisuusjohtamisella varmistat menestymisesi.</p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Tapahtuman turvallisuus- ja pelastussuunnitelma" link="/asiantuntijapalvelut/tapahtuman-turvallisuus-ja-pelastussuunnitelma" />
                <p>Tapahtuman hyvällä turvallisuus- ja pelastussuunnittelulla olet paremmin varautunut erilaisiin tapahtuman järjestämiseen ja läpivientiin liittyviin riskeihin. Asianmukaisella ja ammattimaisella suunnittelulla varmistat asiakkaiden ja henkilökunnan turvallisuuden sekä tehokkaan toiminnan mahdollisissa häiriötilanteissa.</p>
                <p>Asiantuntijamme arvioivat tapahtumasi erityispiirteet ja laativat suunnitelman lain ja viranomaisten vaatimusten mukaisiksi.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/asiantuntijapalvelut/tapahtuman-turvallisuus-ja-pelastussuunnitelma">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Tapahtuman ensiapusuunnitelma" link="/asiantuntijapalvelut/ensiapusuunnitelma" />
                <p>Tapahtuman ensiapusuunnittelulla varmistat riittävän varautumisen eri asteisiin ensiapua vaativiin tilanteisiin. Ensiapusuunnitelma tulee laatia asiakaskapasiteetiltaan yli 2000 hengen yleisötapahtumiin tai mikäli viranomainen sitä perustellusta syystä edellyttää.</p>
                <p>Asiantuntijamme arvioivat tapahtumasi erityispiirteet ja laativat suunnitelman lain ja viranomaisten vaatimusten mukaisiksi.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/asiantuntijapalvelut/ensiapusuunnitelma">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Tapahtuman liikenteenohjaussuunnitelma" link="/asiantuntijapalvelut/liikenteenohjaussuunnitelma" />
                <p>Joskus tapahtumapaikan sijainti ja sitä ympäröivä alue saattaa asettaa vaatimuksia tavanomaisten liikennejärjestelyiden muutoksille. Liikenteenohjaussuunnitelmalla varaudutaan liikenteen aiheuttamien riskien ennaltaehkäisemiseen sekä liikenteen sujuvuuden ylläpitämiseen tapahtuman erikoisjärjestelyistä huolimatta. Liikennejärjestelyiden muutokset vaativat usen monitahoista yhteistyötä viranomaisten, kuntien ja muiden sidosryhmien kanssa.</p>

                <p>Asiantuntijamme arvioivat tapahtumasi erityispiirteet ja laativat suunnitelman lain ja viranomaisten vaatimusten mukaisiksi.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/asiantuntijapalvelut/liikenteenohjaussuunnitelma">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Kiinteistön pelastussuunnitelma" link="/asiantuntijapalvelut/kiinteiston-pelastussuunnitelma" />
                <p>Pelastussuunnitelman avulla rakennuksen omistajalla, haltijalla ja tai toiminnanharjoittajalla on edellytykset tunnistaa rakennukseen ja siinä tapahtuvaan toimintaan liittyvät vaarat sekä miten erilaisiin vaaratekijöihin on varauduttu. Pelastussuunnitelmassa tulee vähimmäisvaatimuksena huomioida omatoimisen varautumisen ja tulipalojen ennaltaehkäisyn säännökset sekä siinä tulee kuvata rakennuksen turvallinen käyttö ja toiminta.</p>
                <p>Asiantuntijamme tutustuvat kiinteistöön ja laativat saatujen tietojen pohjalta lain vaatimukset täyttävän pelastussuunnitelman ja toimintaohjeet.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/asiantuntijapalvelut/kiinteiston-pelastussuunnitelma">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Turvallisuuspäällikkö" link="/asiantuntijapalvelut/turvallisuuspaallikko" />
                <p>Turvallisuuspäällikkö keskittyy organisaation ja yritystoiminnan kokonaisvaltaisen turvallisuuden hallintaan. Tehtäviin kuuluvat turvallisuusjohtaminen, riskien arviointi ja hallinta, turvallisuuskäytäntöjen kehittäminen, yrityksen omatoimisen varautumisen ja hätätilanteiden suunnittelu, henkilöstön koulutus ja viranomaisyhteistyö.</p>
                <p>Turvallisuuspäällikkö varmistaa, että organisaatio on varautunut ja valmistautunut reagoimaan tehokkaasti erilaisiin turvallisuushaasteisiin, edistäen siten turvallista toimintaympäristöä ja yritysturvallisuutta.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/asiantuntijapalvelut/turvallisuuspaallikko">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Turvallisuusvalvoja" link="/asiantuntijapalvelut/turvallisuusvalvoja" />
                <p>Turvallisuusvalvojat osallistuvat erilaisiin turvallisuuden valvontatehtäviin, joissa vaaditaan perehtyneisyyttä tai muuta koulutusta erilaisista valvottavista asioista. Turvallisuusvalvojat voivat valvoa tehtävissään erilaisten turvallisuusjärjestelmien toimivuutta sekä suorittaa tarkastuskierroksia alueilla ja tiloissa. Turvallisuusvalvojat voivat suorittaa myös erilaisia terveys-, työ- ja ympäristöturvallisuuden valvonta tehtäviä osana turvallisuus- tai HSE- organisaatiota.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/asiantuntijapalvelut/turvallisuusvalvoja">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="HSE-Valvoja" link="/asiantuntijapalvelut/hse-valvoja"/>
                <p>HSE-Valvojat (Health, Safety, Environment) ovat tehtävissään erikoistuneet valvomaan ja kehittämään terveys-, työ- ja ympäristöturvallisuutta. HSE-Valvojat suunnittelevat ja toteuttavat turvallisuuskoulutuksia, suorittavat työn riskien arviointeja, työmaan turvallisuusmittauksia ja varmistavat, että organisaatiot toimivat vastuullisesti sekä lakien, sääntöjen ja ohjeiden mukaisesti. HSE-Valvojat edistävät toiminnallaan kokonaisvaltaista hyvinvointia ja turvallisuutta työympäristössä.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/asiantuntijapalvelut/hse-valvoja">Lue lisää</Link>
                </p>
              </Col>
            </Row>
        </Container>

        <ServicePage23WhyChoose />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_3;
