import React, { Component } from 'react';
import Banner from '../../components/Banner'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Link from 'react-router-dom/Link'
// import Button from 'react-bootstrap/Button'
import './style.scss';

class OpenApplicationPage extends Component {

  constructor(props){
    super(props)
    this.state = {
      etunimi: '',
      sukunimi: '',
      phone: '',
      email: '',
      tyokaupunki: '',
      ajokortti: false,
      auto: false,
      jarjestyksenvalvoja: false,
      jarjestyksenvalvojakortinNumero: '',
      jarjestyksenvalvojakortinVoimassaoloaika: '',
      vartijakortti: false,
      vartijakortinNumero: '',  
      vartijakortinVoimassaoloaika: '',
      muuTehtava: false,
      muuTehtavaKuvaus: '',
      tyokokemusTurvallisuusalalta: '',
      tyokokemusMuiltaAloilta: '',
      vahvuuteni: '',
      harrastukset: '',
      suosittelijat: '',
      cv: null
    }
    this.vartijakorttiRadio = this.vartijakorttiRadio.bind(this);
    this.autoRadio = this.autoRadio.bind(this);
    this.ajoKorttiRadio = this.ajoKorttiRadio.bind(this);
    this.jarjestyksenvalvojaCheckbox = this.jarjestyksenvalvojaCheckbox.bind(this);
    this.vartikakortinCheckbox = this.vartikakortinCheckbox.bind(this);
    this.muuTehtavaCheckbox = this.muuTehtavaCheckbox.bind(this);

    this.setFile = this.setFile.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
  }
  
  vartijakorttiRadio(bool){
    if(bool === true){
      this.setState({ vartijakorttiTrue: true});
    }else{
      this.setState({ vartijakorttiTrue: false});
    }
  }
  autoRadio(bool){
    if(bool === true){
      this.setState({ auto: true});
    }else{
      this.setState({ auto: false});
    }
  }
  ajoKorttiRadio(bool){
    if(bool === true){
      this.setState({ ajokortti: true});
    }else{
      this.setState({ ajokortti: false});
    }
  }
  jarjestyksenvalvojaCheckbox(){
    this.setState({ jarjestyksenvalvoja: !this.state.jarjestyksenvalvoja});
  }
  
  vartikakortinCheckbox(){
    this.setState({ vartijakortti: !this.state.vartijakortti});
  }

  muuTehtavaCheckbox(){
    this.setState({ muuTehtava: !this.state.muuTehtava});
  }

  setFile(event){
    this.setState({ cv: event.target.files[0]});
  }

  handleSubmit(e){
    e.preventDefault();

      console.log(this.state);
    
      this.setState({ submitted: true });
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 50);

      let formData = new FormData();
      formData.append('etunimi', this.state.etunimi);
      formData.append('sukunimi', this.state.sukunimi);
      formData.append('phone', this.state.phone);
      formData.append('email', this.state.email);
      formData.append('tyokaupunki', this.state.tyokaupunki);
      formData.append('auto', this.state.auto);
      formData.append('ajokortti', this.state.ajokortti);
      formData.append('jarjestyksenvalvoja', this.state.jarjestyksenvalvoja);
      formData.append('jarjestyksenvalvojakortinNumero', this.state.jarjestyksenvalvojakortinNumero);
      formData.append('jarjestyksenvalvojakortinVoimassaoloaika', this.state.jarjestyksenvalvojakortinVoimassaoloaika);
      formData.append('vartijakortti', this.state.vartijakortti);
      formData.append('vartijakortinNumero', this.state.vartijakortinNumero);
      formData.append('vartijakortinVoimassaoloaika', this.state.vartijakortinVoimassaoloaika);
      formData.append('muuTehtava', this.state.muuTehtava);
      formData.append('muuTehtavaKuvaus', this.state.muuTehtavaKuvaus);
      formData.append('tyokokemusTurvallisuusalalta', this.state.tyokokemusTurvallisuusalalta);
      formData.append('tyokokemusMuiltaAloilta', this.state.tyokokemusMuiltaAloilta);
      formData.append('vahvuuteni', this.state.vahvuuteni);
      formData.append('harrastukset', this.state.harrastukset);
      formData.append('suosittelijat', this.state.suosittelijat);
      formData.append('cv', this.state.cv);
      formData.append('subscribe', this.state.subscribe);

      fetch('https://forms.campaigns.fi/nordicsec/contact-form-staging.php', {
        method: 'POST',
        body: formData
      });      
  }
  handleChange(e){
    e.preventDefault();
    let value = e.target.value;
    let name = e.target.name;

    this.setState({[name]: value})
  }
  render() {

    let form;

    if(this.state.submitted){
      form =
      <div>
        <h2>Työhakemuksesi on lähetetty!</h2>
        <p>Käymme hakemuksesi läpi ja olemme yhteydessä.</p>
      </div>
    }else{
      form =
      <Form onSubmit={this.handleSubmit}>
                <Form.Row>
                  <Col sm={12} md={6}>
                    <Form.Group controlId="formGroupEmail">
                      <Form.Label>Etunimi *</Form.Label>
                      <Form.Control  onChange={this.handleChange} name="etunimi" type="text"  />
                    </Form.Group>
                  </Col>

                  <Col sm={12} md={6}>
                    <Form.Group controlId="formGroupEmail">
                      <Form.Label>Sukunimi *</Form.Label>
                      <Form.Control  onChange={this.handleChange} name="sukunimi" type="text"  />
                    </Form.Group>
                  </Col>

                  <Col sm={12} md={6}>
                    <Form.Group controlId="formGroupEmail">
                      <Form.Label>Puhelinnumero *</Form.Label>
                      <Form.Control  onChange={this.handleChange} name="phone" type="phone"  />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group sm={12} md={6} controlId="formGroupEmail">
                      <Form.Label>Sähköpostiosoite *</Form.Label>
                      <Form.Control  onChange={this.handleChange} name="email" type="email" />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12} md={6}>
                    <Form.Group controlId="tyokaupunki">
                      <Form.Label>Kaupunki tai alue, josta haen töitä *</Form.Label>
                      <Form.Control  onChange={this.handleChange} name="tyokaupunki" type="text" />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12} md={6}>
                    <Form.Group controlId="ajokortti">
                      <Form.Label>Ajokortti</Form.Label>
                      <Form.Check
                      onClick={() => this.ajoKorttiRadio(true)}
                        type="radio"
                        label="Kyllä"
                        name="ajokortti"
                        id="ajokorttiRadio1"
                      />
                      <Form.Check
                      onClick={() => this.ajoKorttiRadio(false)}
                        defaultChecked
                        type="radio"
                        label="Ei"
                        name="ajokortti"
                        id="ajokorttiRadio2"
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={12} md={6}>
                    <Form.Group controlId="auto">
                      <Form.Label>Auto käytettävissä</Form.Label>
                      <Form.Check
                      onClick={() => this.autoRadio(true)}
                        type="radio"
                        label="Kyllä"
                        name="auto"
                        id="autoRadio1"
                      />
                      <Form.Check
                      onClick={() => this.autoRadio(false)}
                        defaultChecked
                        type="radio"
                        label="Ei"
                        name="auto"
                        id="autoRadio2"
                      />

                    </Form.Group>
                  </Col>
                </Form.Row>
                
                <Form.Row>
                  <Col sm={12} md={6}>
                    <Form.Group>
                      <Form.Label>Työtehtävät, joita haen *</Form.Label>
                      <Form.Check
                        label="Järjestyksenvalvoja"
                        name="jarjestyksenvalvoja"
                        id="jarjestyksenvalvoja"
                        onClick={this.jarjestyksenvalvojaCheckbox}
                        />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12} md={6}>
                    <Form.Group controlId="jarjestyksenvalvojakortin_numero">
                      <Form.Label>Järjestyksenvalvojakortin numero *</Form.Label>
                      <Form.Control onChange={this.handleChange} name="jarjestyksenvalvojakortinNumero" type="text" disabled={!this.state.jarjestyksenvalvoja} />
                    </Form.Group>
                  </Col>

                  <Col sm={12} md={6}>
                    <Form.Group controlId="jarjestyksenvalvojakortin_voimassaoloaika">
                      <Form.Label>Järjestyksenvalvojakortin voimassaoloaika *</Form.Label>
                      <Form.Control onChange={this.handleChange} name="jarjestyksenvalvojakortinVoimassaoloaika" type="text" disabled={!this.state.jarjestyksenvalvoja} />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12} md={6}>
                    <Form.Group>
                      <Form.Check
                        label="Vartija"
                        name="vartija"
                        id="vartija"
                        onClick={this.vartikakortinCheckbox}
                        />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12} md={6}>
                    <Form.Group controlId="vartijakortin_numero">
                      <Form.Label>Vartijakortin numero *</Form.Label>
                      <Form.Control onChange={this.handleChange} name="vartijakortinNumero" type="text" disabled={!this.state.vartijakortti} />
                    </Form.Group>
                  </Col>

                  <Col sm={12} md={6}>
                    <Form.Group controlId="vartijakortin_voimassaoloaika">
                      <Form.Label>Vartijakortin voimassaoloaika *</Form.Label>
                      <Form.Control onChange={this.handleChange} name="vartijakortinVoimassaoloaika" type="text" disabled={!this.state.vartijakortti} />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12} md={6}>
                    <Form.Group>
                      <Form.Check
                        label="Muut tehtävä"
                        name="muuTehtava"
                        id="muuTehtava"
                        onClick={this.muuTehtavaCheckbox}
                        />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12}>
                    <Form.Group controlId="muu_tehtava_kuvaus">
                      <Form.Label>Mihin tehtävään haet *</Form.Label>
                      <Form.Control onChange={this.handleChange} name="muuTehtavaKuvaus" type="text" disabled={!this.state.muuTehtava} />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12}>
                    <Form.Group controlId="tyokokemusTurvallisuusalalta">
                      <Form.Label>Aikaisempi työkokemus turvallisuusalalta (max. 1000 merkkiä)</Form.Label>
                      <Form.Control onChange={this.handleChange} name="tyokokemusTurvallisuusalalta" as="textarea" rows="5" />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12}>
                    <Form.Group controlId="tyokokemusMuiltaAloilta">
                      <Form.Label>Työkokemus muilta aloilta (max. 1000 merkkiä)</Form.Label>
                      <Form.Control  onChange={this.handleChange} name="tyokokemusMuiltaAloilta" as="textarea" rows="5" />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12}>
                    <Form.Group controlId="vahvuuteni">
                      <Form.Label>Vahvuuteni (max. 1000 merkkiä)</Form.Label>
                      <Form.Control  onChange={this.handleChange} name="vahvuuteni" as="textarea" rows="5" />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12}>
                    <Form.Group controlId="harrastukset">
                      <Form.Label>Harrastukset (max. 1000 merkkiä)</Form.Label>
                      <Form.Control  onChange={this.handleChange} name="harrastukset" as="textarea" rows="5" />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group controlId="suosittelijat">
                      <Form.Label>Suosittelijat puhelinnumeroineen (max 1000 merkkiä)</Form.Label>
                      <Form.Control  onChange={this.handleChange} name="suosittelijat" as="textarea" placeholder="(Lupa yhteystietojen antamiseen suosittelutarkoituksissa kysytty)" rows="5" />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12}>
                    <Form.Group controlId="cv">
                      <Form.Label>Liitä ansioluettelo:</Form.Label>
                      <Form.Control type="file" onChange={this.setFile} name="cv" /> 
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12}>
                    <Form.Group controlId="subscribe">
                      <Form.Label>Haluan vastaanottaa Nordic Security Alliance yhtiöiden suoramarkkinointia liittyen Nordic Security Alliance yhtiöiden toimintaan, palveluihin ja työpaikkoihin</Form.Label>
                      <Form.Check
                        type="radio"
                        label="Kyllä"
                        name="subscribe"
                        id="subscribeRadio1"
                      />
                      <Form.Check
                        type="radio"
                        label="Ei"
                        name="subscribe"
                        id="subscribeRadio2"
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col sm={12}>
                    <div className="submit-row">
                      <div className="submit-button">
                        <button className="btn-blue" type="submit">
                          Lähetä
                        </button>
                      </div>

                      <div className="submit-privacy">
                        Henkilötietojasi käsitellään <Link to="/tietosuojaseloste" target="_blank">tietosuojakäytäntömme</Link> mukaisesti. Lähettämällä täytetyn lomakkeen hyväksyt antamiesi henkilötietojen käsittelyn Nordic Security Alliance yhtiöiden toimesta.
                      </div>
                    </div>
                  </Col>
                </Form.Row>
              </Form>
    }

    return (
      <div className="page employeepage">
        <Banner>
            <h1>Työnhakijalle</h1>
        </Banner>

        <Container className="intro p-t-50 p-b-50 text-center">
            <Row>
                <Col sm={{ span: 8, offset: 2}}>
                    <h1>Avoin työhakemus</h1>

                    Jos et löytänyt avoimista työpaikoista sinulle sopivaa tehtävää, täytä alla oleva avoin hakemus. Tallennamme hakemuksesi ja olemme sinuun yhteydessä heti, kun sopiva työpaikka aukeaa.
                    Kannattaa myös <Link to="/tyonhakijalle/liityrekrylistalle">liittyä rekrylistallemme</Link> niin saat aina ensimmäisenä tiedon uusista avoimista työpaikoistamme.
                </Col>
            </Row>
        </Container>

        <Container fluid className="p-t-100 p-b-100 bg-gray">
          <Container>
            <h3>Täytä alla oleva lomake:</h3>

            <Row>
              <Col>
                {form}
              </Col>
            </Row>
          </Container>
        </Container>
      
      </div>
    );
  }
}

export default OpenApplicationPage;
