import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ServiceHeader from '../../components/ServiceHeader';
import ServicePageForm from '../../components/ServicePageForm';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_4 extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <div className="page homepage">       
        <Banner> 
            <h2>Muut palvelut</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50 text-center" id="content">
            <Row>
                <Col sm={{ span: 8, offset: 2}}>
                    <h1>MUUT PALVELUT</h1>
                    <h3>YHTEISEN TURVALLISUUDEN OHEISPALVELUT JA TUOTTEET</h3>
                    <p className="p-b-50">
                      Tarjoamme asiakkaillemme kattavan valikoiman erilaisia turvallisuuteen liittyviä lisäpalveluita ja oheistuotteitta. Turvallisuuden oheispalveluilla ja tuotteilla haluamme tukea asiakkaidemme liiketoimintaa ja parantaa henkilöstön ja tiloissa asioivien turvallisuutta ja viihtyvyyttä.
                    </p>

                    <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </Col>
            </Row>
          </Container>

          <Container fluid className="p-b-25 p-t-25 bg-gray">
            <Row>
              <Col>
                <Container className="bg-white p-b-25 p-t-25 text-center">
                  <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                  <NewReferences references={['noho', 'finlandia', 'oldIrish', 'jokerit', 'rajupaja', 'allas', 'vanha']} />
                </Container>
              </Col>
            </Row>
          </Container>

          <Container className="p-t-25">
            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <h3>Palvelumme - Lisäturvaa erilaisiin tarpeisiin</h3>
                <p>Palveluidemme keskiössä on turvallisuus. Kehitämme jatkuvasti turvallisuuden oheispalveluitamme ja tuotteitamme palvellaksemme asiakkaitamme paremmin sekä tarjotaksemme ratkaisuja heidän turvallisuushuoliinsa ja -ongelmiinsa.</p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50" id="vaatesaeilytys">
                <ServiceHeader title="Vaatesäilytys" />
                <p>Hankkimalla asianmukaisen vaatesäilytysratkaisun, ulkovaatteet ja muut kantamukset eivät loju ympäriinsä, vaan ovat asianmukaisesti säilötty ja järjestyksessä. Näin ei myöskään synny palokuormaa tai turhaa kompastumisvaaraa.</p>
                <p>Toimitamme vaatesäilytysratkaisuja yksityistilaisuuksiin, tapahtumiin ja ravintoloihin. Voimme toimittaa tilapäiset naulakot esimerkiksi yleisö- ja yksityistilaisuuksiin tai suunnitella ravintolasi tiloihin tarpeeseesi sopivan ja toimivan vaatesäilytysratkaisun.</p>
                <br/>
                <p>
                  <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50" id="ensiapu">
                <ServiceHeader title="Ensiapu" />
                <p>Toimitamme ensiapuhenkilöstön kaiken kokoisiin yleisö- ja yksityistilaisuuksiin. Yli 2000 asiakkaan yleisötilaisuuksissa vaaditaan riittävä määrä ensiapuhenkilöstöä, jolla mahdollistetaan nopea ensivaste erilaisissa ja eri tasoisissa ensiapua vaativissa tilanteissa. Saat meiltä niin kiertävät kuin ensiapupisteillä olevat kiinteätkin ensiapu yksiköt. Toimitamme myös tehtävien hoitamiseen tarvittavan ensiapumateriaalin.</p>
                <br/>
                <p>
                  <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50" id="defibrillaattori">
                <ServiceHeader title="Defibrillaattorit" />
                <p>Saat kauttamme defibrillaattorit toimipisteisiisi. Defibrillaattorilla mahdollistat ensiavun tilanteissa, joissa ihminen menee elottomaksi esimerkiksi tapaturman tai sairaskohtauksen seurauksesta. Defibrillaattoria on helppo käyttää ja se antaa käyttäjälleen selkeät ääniohjeet laitteen käyttämisestä.</p>
                <br/>
                <p>
                  <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50" id="liikenteenohjaus">
                <ServiceHeader title="Liikenteenohjaus" />
                <p>Toimitamme liikenteenohjaajia tapahtumien sekä erilaisten rakennus- ja tietyömaiden tarpeisiin. Kaikki liikenteenohjaajamme ovat suorittaneet väyläviraston tieturva 1 koulutuksen. Tehtävän niin vaatiessa koulutamme myös tieturva 2 koulutuksen. Liikenteenohjaajilla varmistat liikenteen sujuvuuden ja turvallisuuden liikenteen poikkeusjärjestelyiden aikana.</p>
                <br/>
                <p>
                  <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50" id="alkometrit">
                <ServiceHeader title="Alkometrit" />
                <p>Saat kauttamme kalibroidut alkometrit esimerkiksi teollisuusprojekteihin, rakennustyömaille tai vaikka lisäpalveluna tapahtumiin. Alkometrit ovat helppo käyttöisiä ja soveltuvat hyvin työkyvyn tai ajokunnon tarkistamiseen. Alkometrit soveltuvat hyvin suurtenkin väkijoukkojen puhalluttamiseen nopeasti ja tehokkaasti.</p>
                <br/>
                <p>
                  <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50" id="turvatekniikka">
                <ServiceHeader title="Turvatekniikka" />
                <p>Turvatekniikka tuo kustannustehokkuutta ja lisäturvaa henkilöstösi, kiinteistösi, liiketilojesi ja alueidesi valvontaan. Erilaisia kamera-, hälytin- ja kulunvalvontajärjestelmä ratkaisuja yhdistelemällä saadaan parannettua henkilöstön ja liiketoiminnan turvallisuutta ja hyvinvointia valvottavalla alueella. Valvontatekniikalla on myös ennaltaehkäisevä vaikutus ja sen avulla voidaan selvittää alueella sattuneita tapahtumia ja rikoksia tehokkaasti myös jälkikäteen.</p>
                <br/>
                <p>
                  <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </p>
              </Col>
            </Row>
        </Container>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_4;
