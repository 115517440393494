import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ServiceHeader from '../../components/ServiceHeader';
import FAQ from '../../components/FAQService1';
import ServicePage21WhyChoose from '../../components/ServicePage21WhyChoose';
import ServicePageForm from '../../components/ServicePageForm';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class EmployeePage extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner> 
            <h2>Työnhakijalle</h2>
        </Banner>
        <Container fluid className="bg-gray">
        <Container className="intro p-t-50 p-b-50 text-center">
            <Row>
                <Col sm={{ span: 8, offset: 2}}>
                    <h1>Hae osaksi kasvavaa ammattilaisten joukkoa</h1>
                    <p>
                      Haemme jatkuvasti joukkoomme uusia turvallisuuden ja asiakaspalvelutyön ammattilaisia.
                    </p>

                    <p>
                      Olitpa sitten opiskelija, vastavalmistunut tai kokenut ammattilainen, meillä on tarjota laaja kirjo erilaisia tehtäviä erilaisista toimintaympäristöistä ja kohteista. Vaatimuksena luvanvaraisissa tehtävissä toimimiseksi edellytämme hakijalta voimassa olevaa hyväksyntää järjestyksenvalvojaksi tai vartijaksi, sekä sujuvaa suomenkielen suullista ja kirjallista osaamista.
                    </p>

                    <p>Meillä voit myös tehdä töitä ja samalla opiskella ammattiin oppisopimusopiskelijana. Oppisopimus on erinomainen tapa kouluttautua ammattiin ja samalla hankkia arvokasta alalla vaadittavaa käytännön kokemusta ja osaamista. Meillä voit kasvaa alan ammattilaiseksi, alan ammattilaisten kanssa.</p>

                    <h4>Miksi liittyä joukkoomme?</h4>
                    <p>Tarjoamme monipuolisia turvallisuuden ja asiakaspalvelutyön tehtäviä erilaisissa toimintaympäristöissä ja kohteissa.</p>

                    <p>Uskomme jatkuvaan oppimiseen ja tuemme henkilökohtaista kasvua ja kehitystä.</p>

                    <p>Olemme työyhteisö, jossa jokaisen panos on merkityksellinen. Yhdessä luomme turvallisuutta ja viihtyisyyttä ympärillemme, jolla saavutamme parhaan asiakaskokemuksen ja lopputuloksen.</p>

                    <p>Arvostamme työntekijöitämme. Tarjoamme kilpailukykyisen palkan sekä muita etuja.</p>

                    <h4>Mitä etsimme?</h4>

                    <p>Etsimme sitoutuneita, vastuullisia ja motivoituneita yksilöitä, jotka haluavat kehittyä ammatillisesti ja olla osana menestyvää yritystämme.</p>

                    <p>Henkilöitä, jotka sitoutuvat <span className="highlight">High-Class - Confidential - Safe</span> periaatteisiimme.</p>

                    <p>Henkilöitä, joille turvallisuuden- ja ihmistenparissa työskentely on lähellä sydäntä.</p>

                    <h4>Miten hakea?</h4>

                    <p>Jos tunnet, että haluat olla osa kehittyvää ja ammattitaitoista työyhteisöämme, tarkista <Link to="/tyonhakijalle/avoimettyopaikat">avoimet työpaikkamme</Link> tai <Link to="/tyonhakijalle/avoinhakemus">täytä avoin hakemus.</Link></p>
                </Col>
            </Row>
          </Container>
          </Container>

          <Container className="p-t-50 p-b-50">
            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <h3>Avoimet työpaikat</h3>

                <p className="p-t-25 p-b-25">Haemme jatkuvasti uusia työntekijöitä useilla eri paikkakunnillla.</p>

                <p>
                  <Link className="btn-standard" to="/tyonhakijalle/avoimettyopaikat">AVOIMET TYÖPAIKAT</Link>
                </p>
              </Col>
            </Row>
          </Container>

          <Container fluid className="bg-gray">
          <Container className="p-t-50 p-b-50">
            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <h3>Avoin hakemus</h3>

                <p className="p-t-25 p-b-25">Jos et löytänyt avoimista työpaikoista sinulle sopivaa tehtävää, jätä meille avoin hakemus.</p>

                <p>
                  <Link className="btn-standard" to="/tyonhakijalle/avoinhakemus">SIIRRY HAKEMUKSEEN</Link>
                </p>
              </Col>
            </Row>
          </Container>
          </Container>

          <Container className="p-t-50 p-b-50">
            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <h3>Liity Suomen suurimmalle yksityisen turvallisuusalan rekrylistalle.</h3>

                <p className="p-t-25 p-b-25">Jos et juuri nyt ole hakemassa meille töihin, mutta olet kiinnostunut tulevaisuuden mahdollisuuksista. Liity sähköpostilistallemme ja saat aina ensimmäisenä tiedon uusista avoimista työpaikoista.</p>

                <p>
                  <Link className="btn-standard" to="/tyonhakijalle/liityrekrylistalle">LIITY REKRYLISTALLE</Link>
                </p>
              </Col>
            </Row>
          </Container>
      </div>
    );
  }
}

export default EmployeePage;
