import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navlink from '../../components/Navlink';
// import Accordion from 'react-bootstrap/Accordion'
// import Card from 'react-bootstrap/Card'
// import Button from 'react-bootstrap/Button'
import Banner from '../../components/Banner'


class NotFoundPage extends Component {
  render() {
    return (
      <div className="page referencespage">
        <Banner>
          <h1>Sivua ei löytynyt</h1>
        </Banner>

        <Container>
          <Row className="text-center p-t-100 p-b-100">
            <Col span={12}>
                <h2>Hakemaasi sivua ei löytynyt</h2>
                <p>
                  Jokin meni vikaan, palaa <Navlink to="/">etusivulle</Navlink>.
                </p>

               
            </Col>
          </Row>


        </Container>
      </div>
    );
  }
}

export default NotFoundPage;
