import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Banner from '../../components/Banner'
import './style.scss';

class GDPRPage extends Component {
  render() {
    return (
      <div className="page servicespage">
        <Banner>
          <h1>Tietosuojaseloste</h1>
        </Banner>

        <Container>
          <Row>
            <Col lg={{span: 8, offset: 2}} md={12}>
                <Row className="p-t-100 ">
                    <Col span={12}>
                        <h3>Seloste henkilötietojen käsittelystä</h3>

                        <p>Yksityisyytesi on meille tärkeää ja käsittelemme henkilötietojasi huolellisesti ja asianmukaisesti.</p>
                    </Col>
                </Row>

                <Row className="p-t-100 ">
                    <Col span={12}>
                        <h3>Rekisterinpitäjä</h3>

                        <p>
                            Nordic Security Alliance Helsinki Oy<br/>
                            y-tunnus: 2406929-9<br/>
                            Ruosilankuja 3 E, 00390 Helsinki<br/>
                            Sähköposti:&nbsp;&nbsp;toimisto@nordicsec.fi<br/>
                            <br/>
                            Tiedustelut henkilötietojen käsittelyyn liittyvissä asioissa<br/>
                            Sähköposti:&nbsp;&nbsp;toimisto@nordicsec.fi<br/>
                        </p>
                    </Col>
                </Row>

                <Row className="p-t-100 ">
                    <Col span={12}>
                        <h3>Henkilötietojen käsittelyn peruste ja tarkoitus</h3>

                        <p>Käsittelemme asiakasrekisterin tietoja asiakassuhteen, rekrytoinnin hoitamisessa, ylläpitämisessä, kehittämisessä ja asiakasviestintää varten.  Asiakkaalla on milloin tahansa oikeus kieltäytyä markkinointiviestinnästä.</p>

                        <p>Potentiaalisten asiakkaiden tietoja käsittelemme toiminnastamme tiedottamiseksi ja markkinoimiseksi oikeutettuun etuumme perustuen. Käsittelemme verkkosivuillamme kävijöiden tietoja oikeutettuun etuumme perustuen sivujemme toiminnallisuuden varmistamiseksi sekä sivuston kehittämiseksi.</p>

                        <p>Työnhakijarekisterin tietoja käsittelemme rekrytointipäätösten tekemistä varten oikeutettuun etuumme perustuen. Oikeutettu etu muodostuu tarpeesta käsitellä henkilötietoja rekrytointitarkoituksiin ja työsopimuksen valmistelemiseksi.</p>

                        <p>Emme käytä henkilötietoja automaattiseen päätöksentekoon.</p>
                        </Col>
                </Row>

                <Row className="p-t-100 ">
                    <Col span={12}>
                        <h3>Käsiteltävät henkilötiedot</h3>

                        <p>Käsittelemme vain henkilötietoja, jotka ovat tarpeellisia edellä kerrottujen tarkoitusten toteuttamiseksi.</p>

                        <p>Asiakkaidemme osalta käsittelemme seuraavia tietoja: asiakkaan edustajan nimi, tehtävänimike ja yhteystiedot, kuten nimi, osoite, puhelinnumero ja sähköpostiosoite.</p>

                        <p>Työnhakijoiden tiedoista käsittelemme seuraavia tietoja: nimi, osoite, sähköpostiosoite, puhelinnumero, työhistoria, koulutustiedot, osaaminen, kielitaito, mahdolliset viranomaislausunnot tarvittaessa, mahdolliset suositukset ja luottotiedot.</p>
                        </Col>
                </Row>

                <Row className="p-t-100 ">
                    <Col span={12}>
                        <h3>Henkilötietojen luovutus ja siirto</h3>

                        <p>Emme lähtökohtaisesti luovuta henkilötietoja muille kolmansille osapuolille. Lainsäädännön edellyttämissä tapauksissa tai viranomaisen niin vaatiessa luovutamme tietoja. Arvioimme luovutuksen lainmukaisuuden tapauskohtaisesti.</p>

                        <p>Lähtökohtaisesti henkilötietoja ei luovuteta EU:n tai ETA-alueen ulkopuolelle. Palveluntarjoajat, jotka tarjoavat työkaluja verkkosivuillemme, saattavat kuitenkin sijaita myös EU:n tai ETA-alueen ulkopuolella, esimerkiksi Google Analytics. Tietosuojan riittävä taso varmistetaan soveltamalla siirtoon tietosuojasääntelyn mukaisia suojatoimia.</p>

                        </Col>
                </Row>

                <Row className="p-t-100 ">
                    <Col span={12}>
                        <h3>Henkilötietojen suojaaminen</h3>

                        <p>Tietojen luottamuksellisuus on meille ensisijaisen tärkeää. Suojaamme henkilötiedot teknisin ja organisatorisin suojakeinoin. Pääsy järjestelmiimme on rajattu käyttöoikeuksin rooleihin perustuen. Kaikilla käyttäjillä on henkilökohtainen käyttäjätunnus ja salasana järjestelmiin.</p>

                        </Col>
                </Row>

                <Row className="p-t-100 ">
                    <Col span={12}>
                        <h3>Tietojen säilytysaika</h3>

                        <p>Henkilötietoja säilytetään niin kauan kuin se on tarpeellista tässä selosteessa määritettyjen käyttötarkoitusten toteuttamiseksi sekä velvoittavan lainsäädännön täyttämiseksi.</p>

                        </Col>
                </Row>

                <Row className="p-t-100 ">
                    <Col span={12}>
                        <h3>Rekisteröidyn oikeudet</h3>

                        <p>Rekisteröidyllä on oikeus saada tieto siitä, mitä henkilötietoja hänestä käsittelemme ja miten, saada epätarkat tai virheelliset tiedot oikaistua tai vahvistus siitä, ettemme käsittele häntä koskevia tietoja. Rekisteröidyllä on tietyissä tapauksissa oikeus tulla unohdetuksi, oikeus henkilötietojen käsittelyn rajoittamiseen tai oikeus vastustaa henkilötietojen käsittelyä. Mikäli rekisteröity esittää jonkin edellä sanotuista vaateista, tutkimme vaatimukset ja toimimme aina laissa säädettyjä vaatimuksia noudattaen, rekisteröidyn oikeuksista huolehtien.</p>

                        <p>Rekisteröidyllä on tietyin edellytyksin oikeus siirtää toiselle rekisterinpitäjälle meille toimittamansa, häntä koskevat henkilötiedot, oikeus vastustaa tai rajoittaa henkilötietojen käsittelyä.</p>

                        <p>Rekisteröidyllä on oikeus tehdä valitus valvontaviranomaiselle, mikäli hän katsoo, että henkilötietoja on käsitelty lainvastaisesti.</p>

                        <p>Rekisteröity voi käyttää oikeuksiaan ottamalla meihin yhteyttä kirjallisesti riittävän yksilöidysti pyynnön toteuttamiseksi ja henkilöllisyyden varmistamiseksi.</p>

                        <p>Lisätietoja henkilötietojen käsittelyyn liittyen saa ottamalla yhteyttä:<br/>
                        toimisto@nordicsec.fi</p>

                        </Col>
                </Row>

                <Row className="p-t-100 ">
                    <Col span={12}>
                        <h3>Muutokset selosteeseen</h3>

                        <p>Toiminnan kehityksen, henkilötietojen käsittelyn ja lainsäädännön muutosten johdosta pidätämme oikeuden tämän selosteen muuttamiseen. Päivitetty seloste on aina saatavilla verkkosivuillamme.</p>

                        </Col>
                </Row>

                <Row className="p-t-100 ">
                    <Col span={12}>
                        <h3>Evästeet</h3>

                        <p>Käytämme sivustollamme evästeitä. Jatkamalla sivuston selaamista hyväksyt evästeiden käytön. Evästeitä käytetään vierailijoiden käyttötavan seurantaan, jolla kehitämme verkkosivustoamme, tuotteitamme, palvelujamme ja markkinointiamme (analyysievästeet).</p>
                        
                        <p>
                            Google Analytics:_utma (2 vuotta); _utmb (30 min); _utmc (selainistunto); _utmz (6 kuukautta), _ga(2 vuotta), _gat(10 min)<br/>
                            Domain: www.google.com<br/>
                            Lue Googlen yksityisyyden suojasta:<br/>
                            <a href="https://support.google.com/adsense/answer/2839090?hl=fi">https://support.google.com/adsense/answer/2839090?hl=fi</a><br/>
                            <a href="http://www.google.com/intl/fi/policies/privacy/">http://www.google.com/intl/fi/policies/privacy/</a><br/>
                        </p>

                        <p>Näiden evästeiden avulla keräämme tietoa siitä, kuinka kävijät käyttävät sivustoamme. Näin voimme kehittää verkkosivustoamme, tuotteitamme, palvelujamme ja markkinointistrategioitamme. Google linkittää tietoa Google Analyticsiin myös muista Googlen omistamista verkkopalveluista, kuten Google hausta, YouTubesta, karttapalvelusta sekä "DoubleClickistä".</p>
                        </Col>
                </Row>

                <Row className="p-t-100 ">
                    <Col span={12}>

                    </Col>
                </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default GDPRPage;
