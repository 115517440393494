import React, { Component } from 'react';
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import { HashLink as Link } from 'react-router-hash-link';
import './style.scss';

class ContactPerson extends Component {
  render() {

    let title = <span></span>;
    if(this.props.title){
        title = <span><br /><strong>{this.props.title}</strong></span>;
    }

    let image = <span></span>;
    if (this.props.image) {
      image = <span className="image" style={{backgroundImage: `url(${this.props.image}`}} alt={this.props.name}><br/><br/></span>;
    } else {
      image = <span className="image" alt={this.props.name}><br/><br/></span>;
    }

    return (
        
        <p className="contactPerson">
            {image}
            <strong>{this.props.name}</strong>
            
            {title}
            <br />
            <a href={"tel:" + this.props.phonelink}>{this.props.phone}</a>
            <br />
            <a href={"mailto:" + this.props.email}>{this.props.email}</a>
        </p>


    );
  }
}

export default ContactPerson;
