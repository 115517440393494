import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQService3';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage23WhyChoose from '../../components/ServicePage23WhyChoose';
import Testimonial from '../../components/Testimonial';
import ServicePageForm from '../../components/ServicePageForm';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_3_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
          <h2>Asiantuntijapalvelut</h2>
        </Banner>
        <Container className="intro p-t-100 p-b-50 text-center" id="content">
          <Row className="p-b-100">
            <Col sm={{ span: 8, offset: 2}}>
              <h1>TURVALLISUUSVALVOJA</h1>
              <h3>TURVALLISUUDEN SILMINÄ JA KORVINA</h3>
              <p>
                Turvallisuusvalvojan tehtävät voivat olla varsin monitasoisia. Tyypillisesti siihen liittyy erilaisten turvallisuuteen ja turvallisuusjärjestelmien valvontaan ja toimivuuteen liittyvät tehtävät, mutta ne voivat sisältää myös turvallisuuden yleisvalvontaa ja tarkastuskierroksia osana HSE- tai turvallisuusorganisaatiota. Turvallisuusvalvojat voivat hoitaa myös tulityövartiointia ja säiliötyövalvontaa osana työtehtäviään.
              </p>

              <h4>Miksi turvallisuusvalvojat ovat tärkeitä?</h4>

              <p className="p-b-50">
                Turvallisuusvalvojat ovat kustannustehokas tapa lisätä turvallisuudenvalvontaa ja he ovat hyvä lisä turvallisuusorganisaatioihin suorittamaan operatiivisia valvontatehtäviä valvottavalla alueella. He voivat laatia turvallisuushavaintoja tai raportteja valvottavalta alueelta ja heitä voidaan kouluttaa erilaisten turvallisuusjärjestelmien ylläpito- ja valvontatehtäviin. Turvallisuusvalvojat voivat myös toimia ensiaputilanteissa tarjoamalla nopeaa apua ja varmistamalla, että hätätilanteisiin vastataan asianmukaisesti.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray text-center">
          <Row>
            <Col>
              <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
              <NewReferences references={['noho', 'finlandia', 'oldIrish', 'jokerit', 'rajupaja', 'allas', 'vanha', 'jt']} />
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Turvallisuusvalvonta</h4>
              <p>Yleinen terveys-, työ ja ympäristöturvallisuuden (HSE) valvonta sekä rikosten ennaltaehkäisy valvottavalla alueella.</p>

              <h4>Turvallisuusjärjestelmien valvonta ja ylläpito</h4>
              <p>Erilaisten turvallisuusjärjestelmien valvonta ja ylläpitotehtävät.</p>

              <h4>Tulityövartiointi</h4>
              <p>Tulityökohteiden tulityövartiointi, tulitöiden jälkivartiointi ja paloturvallisuuden varmistaminen.</p>

              <h4>Säiliötöiden valvonta</h4>
              <p>Säiliöissä tai montuissa tehtävien töiden turvallisuuden valvonta ja varmistaminen.</p>

              <h4>Nostotyöt</h4>
              <p>Nostotöiden turvallisuuden valvominen ja varmistaminen, nostotöiden varoalueiden rajaaminen ja kulunvalvominen rajatulla alueella.</p>

              <h4>Ensiapu</h4>
              <p>Hätäensiapu ja pelastustoiminnan aloittaminen ensiapu ja pelastustilanteissa, avun hälyttäminen ja avun paikalle opastaminen.</p>

              <h4>Raportointi</h4>
              <p>Turvallisuushavainnoista, -laiminlyönneistä ja -puutteista raportoiminen.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/vartiointi/tyomaajateollisuusvartiointi">Työmaa- ja teollisuusvartiointi</Link>
          <Link to="/asiantuntijapalvelut/hse-valvoja">HSE-Valvoja</Link>
          <Link to="/muutpalvelut/defibrillaattori">Defibrilaattorit</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage23WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_3_1;
