import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQService2x';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage22WhyChoose from '../../components/ServicePage22WhyChoose';
import ServicePageForm from '../../components/ServicePageForm';
import Testimonial from '../../components/Testimonial';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_1_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
          <h2>Vartiointipalvelut</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50 text-center" id="content">
          <Row>
            <Col sm={{ span: 8, offset: 2}}>
              <h1>ETSIVÄ- JA RIKOSTENPALJASTAMISTOIMINTA</h1>
              <h3>LUOTETTAVAA JA AMMATTIMAISTA VARTIOINTIPALVELUA</h3>
              <p>
                Etsivä- ja rikostenpaljastamistoiminta soveltuu kaikenkokoisiin vähittäis- ja päivittäistavarakauppoihin, hypermarketteihin, tavarataloihin tai muihin erikoisliikkeisiin, joissa on tarve näkymättömälle rikosten paljastamiseksi tai ennaltaehkäisemiseksi suunnatulle toiminnalle. Etsivä- ja rikostenpaljastamistoimintaa voivat hyödyntää myös muut yritykset, organisaatiot tai yksityiset henkilöt, joilla on tarve erilaisten asiakokonaisuuksien tai rikosten ratkaisemiselle.
              </p>

              <h4>Miksi vartiointipalvelut ovat tärkeitä?</h4>
              <p className="p-b-50">
                Etsivä- ja rikostenpaljastamistoiminnalla voit selvittää rikoskokonaisuuksia, joihin omat tai viranomaisen ajalliset resurssit eivät riitä. Palvelun avulla voi tutkia myös muita yritystoiminnan tai henkilökohtaisen elämän asiakokonaisuuksia, joiden selvittäminen voi olla tärkeää. Asiakkaalle luovutetaan kaikki materiaali tutkittavaan asiaan liittyen kirjallisen raportin kanssa.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray">
          <Row>
            <Col>
              <Container className="bg-white p-b-25 p-t-25 text-center">
                <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                <NewReferences references={['noho', 'finlandia', 'rajupaja', 'kmarket', 'ksupermarket', 'eurooppa', 'familar', 'humana', 'lauste']} />
              </Container>
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>          
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Henkilöturvallisuus</h4>
              <p>Henkilökunnan turvallisuuden ja työhyvinvoinnin suojaaminen erilaisilta rikoksilta, henkilöhälytyksiin vastaaminen kohteessa, ensiapu.</p>

              <h4>Omaisuuden suojaaminen</h4>
              <p>Tuotteiden ja omaisuuden suojaaminen rikoksilta, näpistysten ja varkauksien estäminen ja ennaltaehkäiseminen.</p>

              <h4>Aluevartiointi</h4>
              <p>Aluevartiointi jalan tai ajoneuvolla, tarkastuskierrokset, avaus- ja sulkukierrokset, työ-, palo- ja pelastusturvallisuuden valvonta, hälytysjärjestelmien aktivointi, hälytyksiin vastaaminen.</p>

              <h4>Omaisuuden tai kohteen tekninen valvonta ja seuranta.</h4>
              <p>Tutkinta aineiston kerääminen avoimista lähteistä, järjestelmistä ja muilla keinoin.</p>

              <h4>Raportointi</h4>
              <p>Tutkinnan tuloksista raportointi.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/asiantuntijapalvelut/kiinteiston-pelastussuunnitelma">Kiinteistön pelastussuunnittelma</Link>
          <Link to="/muutpalvelut/radiopuhelin">Radiopuhelimet</Link>
          <Link to="/muutpalvelut/defibrillaattori">Defibrilaattorit</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage22WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_1_1;
