import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQService2x';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePageForm from '../../components/ServicePageForm';
import ServicePage22WhyChoose from '../../components/ServicePage22WhyChoose';
import Testimonial from '../../components/Testimonial';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_1_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
          <h2>Vartiointipalvelut</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50 text-center" id="content">
          <Row className="p-b-50">
            <Col sm={{ span: 8, offset: 2}}>
              <h1>AULAPALVELU</h1>
              <h3>LUOTETTAVAA JA AMMATTIMAISTA VARTIOINTIPALVELUA</h3>
              <p>
                Tuotamme aulapalveluita erilaisten tapahtumatalojen, kauppakeskusten sekä yritys- ja  liikekiinteistöjen tarpeisiin. Huomioimme toiminnassamme kohdekohtaiset erityispiirteet ja räätälöimme palvelumme sopivaksi yhdessä asiakkaamme kanssa. Aulapalveluiden keskiössä on turvallisuus ja asiakaspalvelu. Aulavartijamme ovat koulutettuja turvallisuuden ja asiakaspalvelun ammattilaisia.
              </p>

              <h4>Miksi vartiointipalvelut ovat tärkeitä?</h4>
              <p className="p-b-50">
                Aulapalveluilla varmistat, että kaikki vieraat ovat tervetulleita ja että heidät tunnistetaan asianmukaisesti. Samalla varmistat sujuvan asioinnin kohteessasi ja ylläpidät turvallisuutta. Aulapalvelut parhaimmillaan vaikuttavat positiivisesti asiakkaiden ja vieraiden ensivaikutelmaan sekä kohteen yleiseen päivittäistoimintaan.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray">
          <Row>
            <Col>
              <Container className="bg-white p-b-25 p-t-25 text-center">
                <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                <NewReferences references={['noho', 'finlandia', 'rajupaja', 'kmarket', 'ksupermarket', 'eurooppa', 'familar', 'humana', 'lauste']} />
              </Container>
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Kulunvalvonta</h4>
              <p>Vastaanotto- ja kulkulupa asiat, logistiikan ohjaus, asiakaspalvelu.</p>

              <h4>Vastaanotto- ja postituspalvelu</h4>
              <p>Asiakas- ja vierailija sähköpostien hallinta, sisäisen ja ulkoisen postin käsittely.</p>

              <h4>Kohdevartiointi</h4>
              <p>Tarkastuskierrokset, avaus- ja sulkukierrokset, työ-, palo- ja pelastusturvallisuuden valvonta, hälytysjärjestelmien aktivointi, hälytyksiin vastaaminen.</p>

              <h4>Henkilöturvallisuus</h4>
              <p>Henkilökunnan turvallisuuden ja työhyvinvoinnin suojaaminen erilaisilta rikoksilta, henkilöhälytyksiin vastaaminen kohteessa, ensiapu.</p>

              <h4>Omaisuuden suojaaminen</h4>
              <p>Aineellisen ja aineettoman omaisuuden suojaaminen erilaisilta rikoksilta ja vahingonteolta, erilaisiin laitteisto- ja järjestelmähälytyksiin vastaaminen kohteessa.</p>

              <h4>Avainhallinta</h4>
              <p>Kiinteistön tai alueen avainhallinta, oven avaukset.</p>

              <h4>Tekninen valvonta</h4>
              <p>Kameravalvonta ja kameravalvonnan suunnittelu, rikos-, palo- ja LVIS -järjestelmien valvonta.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/asiantuntijapalvelut/turvallisuuspaallikko">Turvallisuuspäällikkö</Link>
          <Link to="/asiantuntijapalvelut/kiinteiston-pelastussuunnitelma">Kiinteistön pelastussuunnittelma</Link>
          <Link to="/muutpalvelut/defibrillaattori">Defibrilaattorit</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage22WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_1_1;
