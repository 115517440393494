import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class FAQ extends Component {
  render() {
    return (
      <Container fluid className="p-b-50 p-t-50">
        <Row>
          <Col>
            <Container>
              <Row>
                <Col sm={{ span: 8, offset: 2 }}>
                  <h3 className="p-b-25">Meiltä usein kysyttyä:</h3>

                  <h4>Mikä on asiantuntijapalveluidemme hintataso?</h4>
                  <p>Hinta riippuu täysin asiakkaan tarpeesta, toimialasta ja tehtävän vaativuudesta. Parhaiten pystymme vastaamaan kun olemme kartoittaneet tilanteesi ja tehtävän vaativuustason.</p>

                  <h4>Mihin toimitatte asiantuntijoita?</h4>
                  <p>Tällähetkellä suurin kysyntä palvelullemme on rakentamisen, teollisuuden ja tapahtumatuotannon toimialoilla. Tuotamme palvelua myös muille toimialoille.</p>

                  <h4>Mitä asiantuntijan tehtäviin sisältyy?</h4>
                  <p>Asiakkaidemme tarpeet ovat moninaisia, joten on vaikea antaa yksiselitteistä vastausta kaikista tehtävistä. Usein tehtäviin sisältyy riskien arviointia ja hallintaa, asiakkaan prosessien muovaamista, koulutusta, turvallisuussuunnittelua, auditointeja sekä erilaisia turvallisuuden kehittämisprojekteja.</p>

                  <h4>Miksi tarvitsen turvallisuuden asiantuntijapalveluita organisaatiolleni?</h4>
                  <p>Turvallisuuden asiantuntijapalvelut voivat olla tarpeellisia organisaatiolle riskienhallinnassa, turvallisuuskulttuurin vahvistamisessa ja varautumisessa erilaisiin uhkiin. Ulkoistamalla turvallisuuden asiantuntijapalvelut osittain tai kokonaan saadaan uusia näkökulmia ja ajatuksia organisaation päätöksenteon tueksi. Tehtävän vaatimaa osaamista ei välttämättä löydy omasta organisaatiosta tai tehtävän ulkoistaminen on voi yksinkertaisesti olla taloudellisesti järkevämpää.</p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FAQ;
