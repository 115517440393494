import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQService2x';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage22WhyChoose from '../../components/ServicePage22WhyChoose';
import ServicePageForm from '../../components/ServicePageForm';
import Testimonial from '../../components/Testimonial';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_1_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
          <h2>Vartiointipalvelut</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50 text-center" id="content">
          <Row>
            <Col sm={{ span: 8, offset: 2}}>
              <h1>LASTENSUOJELU</h1>
              <h3>LUOTETTAVAA JA AMMATTIMAISTA VARTIOINTIPALVELUA</h3>
              <p>
                Tuotamme vartiointipalveluita erilaisiin ja eri kokoisiin lastensuojelun ja nuorisotyön toimipisteisiin. Huomioimme toiminnassamme erilaiset lastensuojelun ja nuorisotyön erityispiirteet. Pääpaino tehtävissämme on henkilöturvallisuudessa ja tilanteiden ennaltaehkäisyssä. Koulutamme henkilöstöämme toimimaan lastensuojelulain mukaisesti. Toimintamme on asiakaslähtöistä ja ammattimaista.
              </p>

              <h4>Miksi vartiointipalvelut ovat tärkeitä?</h4>
              <p className="p-b-50">
                Vartiointipalveluilla varmistat henkilöstösi ja asiakkaittesi turvallisuuden. Paikalla on aina turvallinen henkilö, joka puuttuu tai tulee väliin tilanteiden eskaloituessa. Vartijan läsnäololla on merkittävä ennaltaehkäisevä vaikutus mikä tuo turvaa myös asiakkaina oleville lapsille ja nuorille.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray">
          <Row>
            <Col>
              <Container className="bg-white p-b-25 p-t-25 text-center">
                <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                <NewReferences references={['noho', 'finlandia', 'rajupaja', 'kmarket', 'ksupermarket', 'eurooppa', 'familar', 'humana', 'lauste']} />
              </Container>
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Henkilöturvallisuus</h4>
              <p>Henkilökunnan ja asiakkaiden turvallisuuden ja työhyvinvoinnin suojaaminen erilaisilta rikoksilta, saatto tehtävät, henkilöhälytyksiin vastaaminen kohteessa, ensiapu.</p>

              <h4>Omaisuuden suojaaminen</h4>
              <p>Asiakkaan aineellisen ja aineettoman omaisuuden suojaaminen erilaisilta rikoksilta ja vahingonteolta, erilaisiin laitteisto- ja järjestelmähälytyksiin vastaaminen kohteessa.</p>

              <h4>Kohdevartiointi</h4>
              <p>Näkyvä valvonta ja läsnäolo kohteessa, tarkastuskierrokset, avaus- ja sulkukierrokset, työ-, palo- ja pelastusturvallisuuden valvonta, hälytyksiin vastaaminen.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/asiantuntijapalvelut/kiinteiston-pelastussuunnitelma">Kiinteistön pelastussuunnittelma</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage22WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_1_1;
