import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './style.scss';

class Testimonial extends Component {
  constructor(props) {   
    super(props);

    this.state = {
      page: 0
    };

    this.testimonialsRef = React.createRef();
    this.scrollToPage = this.scrollToPage.bind(this);
  }

  componentDidMount() {

  }

  scrollToPage(page) {
    if(page < 0) {
      page = this.props.testimonials.length - 1;
    } else if(page >= this.props.testimonials.length) {
      page = 0;
    }

    this.setState({page: page});
    this.testimonialsRef.current.scrollTo({
      left: this.testimonialsRef.current.clientWidth * page,
      behavior: 'smooth'
    });
  }

  render() {
    return <div></div>

    return (
      <Container className="p-t-50 text-center">
        <Row>
          <Col>
            <h3 className="testimonial-header">Mitä asiakkaat sanovat meistä:</h3>

            <div className="testimonial-carousel">
              <div className={"arrow left" + (this.state.page === 0 ? ' disabled' : '')} onClick={() => {this.scrollToPage(this.state.page - 1)}}>&#10094;</div>
              <div className={"arrow right" + (this.state.page === this.props.testimonials.length - 1 ? ' disabled' : '')} onClick={() => {this.scrollToPage(this.state.page + 1)}}>&#10095;</div>

              <div className="testimonials" ref={this.testimonialsRef}>
                {this.props.testimonials.map((testimonial, index) => {
                  return <div class="testimonial">
                    <span className="image"><br/><br/></span>
                    <div className="quote">
                      {testimonial.text}
                    </div>

                    <div className="name">
                      {testimonial.name}
                    </div>

                    <div className="title">
                      {testimonial.title}
                    </div>
                  </div>
                })}
              </div>

              <div className="testimonial-carousel-dots">
                {this.props.testimonials.map((testimonial, index) => {
                  return <span className={"dot " + (this.state.page === index ? 'active' : '')} onClick={() => {this.scrollToPage(index)}}></span>
                })}
              </div>

              <a class="btn-grey" href="#">PYYDÄ TARJOUS</a>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

Testimonial.defaultProps = {
  testimonials: [
    {
      name: 'Etunimi Sukunimi',
      title: 'Titteli, Yritys Oy',
      text: 'Tähän asiakkaan kommentit NSA:sta ja yhteistyöstä NSA kanssa. Käytetään 1-3 lausetta.',
    },
    {
      name: 'Etunimi Sukunimi',
      title: 'Titteli, Yritys Oy',
      text: 'Tähän asiakkaan kommentit NSA:sta ja yhteistyöstä NSA kanssa. Käytetään 1-3 lausetta.',
    },
  ]
};

export default Testimonial