import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class FAQ extends Component {
  render() {
    return (
      <Container fluid className="p-b-50 p-t-50">
        <Row>
          <Col>
            <Container>
              <Row>
                <Col sm={{ span: 8, offset: 2 }}>
                  <h3>Meiltä usein kysyttyä:</h3>

                  <h4>Mikä on vartiointipalveluidemme hintataso?</h4>
                  <p>Hinta riippuu täysin asiakkaan tarpeesta, toimialasta, tehtävän vaativuudesta sekä henkilöstön koulutusvaatimuksista. Parhaiten pystymme vastaamaan kun olemme kartoittaneet tilanteesi.</p>

                  <h4>Saako teiltä piiri- ja hälytysvartiointia?</h4>
                  <p>Valitettavasti emme itse tuota piiri- ja hälytysvartiointia tällä hetkellä. Pystymme kuitenkin tarjoamaan piiri- ja hälytysvartiointia alihankintana, laajan yhteistyöverkostomme kautta.</p>

                  <h4>Mitä vartijan tehtäviin sisältyy?</h4>
                  <p>Vartijan tehtävät ja toimialue yksilöidään toimeksiantosopimuksessa. Yleisesti ne voidaan määritellä siten, että tehtävät liittyvät toimeksiantajan liiketoiminnan, henkilöstön ja omaisuuden suojaamiseen.</p>

                  <h4>Mikä ero on vartijalla ja järjestyksenvalvojalla?</h4>
                  <p>Vartija vartioi toimeksiantajan henkilöstöä ja omaisuutta toimeksiantosopimuksessa määritellyllä vartioimisalueella, kun taas järjestyksenvalvoja valvoo yleistä järjestystä ja turvallisuutta sopimuksessa määritellyllä toimialueella.</p>

                  <h4>Kuinka nopeasti vartiointipalvelumme on saatavilla?</h4>
                  <p>Tilanteet saattavat välillä yllättää, jolloin vartiointipalvelun saamisella voi olla kiire. Yhteydenoton jälkeen aluevastaavamme tarkistaa alueellisen resurssin ja ilmoittaa asiakkaalle tilanteen. Teemme aina parhaamme, jotta pystyisimme vaastaamaan myös yllättäviin ja kiireellisiin tilanteisiin.</p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FAQ;
