import React, { Component } from 'react';

import {NavLink} from 'react-router-dom';

class Navlink extends Component {

    scrollToTop(){
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 50);
    }
  render() {
    return (
    <NavLink to={this.props.to} className={this.props.className} onClick={this.scrollToTop.bind(this)}>{this.props.children}</NavLink>
    );
  }
}

export default Navlink;
