import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQService3';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage23WhyChoose from '../../components/ServicePage23WhyChoose';
import Testimonial from '../../components/Testimonial';
import ServicePageForm from '../../components/ServicePageForm';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_3_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
          <h2>Asiantuntijapalvelut</h2>
        </Banner>
        <Container className="intro p-t-100 p-b-50 text-center" id="content">
          <Row className="p-b-100">
            <Col sm={{ span: 8, offset: 2}}>
              <h1>KIINTEISTÖN PELASTUSSUUNNITELMA</h1>
              <h3>KIINTEISTÖN TURVALLISUUDEN JA VARAUTUMISEN YDIN</h3>
              <p>
                Pelastussuunnitelma auttaa tunnistamaan rakennukseen ja siinä tapahtuvaan toimintaan liittyvät vaarat. Pelastussuunnitelmasta löytyy kaikki rakennuksen ja siinä toimivien ihmisten turvallisuuteen liittyvät ja vaikuttavat asiat. Lisäksi pelastussuunnitelmassa kuvataan rakennuksen turvallinen käyttö ja toiminta.
              </p>

              <p className="p-b-50">
                Pelastussuunnitelman laatimisvelvoite tulee pelastuslaista. Pelastussuunnitelmaan sisältyvät vaarojen ja riskien arviointi, kuvaus turvallisuusjärjestelyistä, ohjeet onnettomuuksien ehkäisemiseksi, ohjeet onnettomuus ja vaaratilanteissa toimimiseksi sekä muut kohteen omatoimiseen varautumiseen liittyvät toimenpiteet.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray text-center">
          <Row>
            <Col>
              <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
              <NewReferences references={['noho', 'finlandia', 'oldIrish', 'jokerit', 'rajupaja', 'allas', 'vanha', 'jt']} />
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Alkukartoitus</h4>
              <p>Kartoitamme rakennuksen käyttötarkoituksen, toiminnan, kiinteistöön ja sen ympäristöön liittyvät erityispiirteet.</p>

              <h4>Tiedon kerääminen</h4>
              <p>Rakennukseen ja siinä tapahtuvaan toimintaan tutustuminen, rakennuksen turvallisuus- ja muiden järjestelmien ja järjestelyiden selvittäminen, pohjapiirrosten tarkastus.</p>

              <h4>Riskien arviointi</h4>
              <p>Rakennukseen kohdistuvien riskien arviointi yhdessä rakennuksen haltijan, omistajan tai muun edustajan kanssa.</p>

              <h4>Suunnitelman laatiminen</h4>
              <p>Pelastussuunnitelma laaditaan vaarojen ja riskien arviointiin sekä kerättyyn tietoon ja materiaaliin perustuen.</p>

              <h4>Suunnitelman hyväksyminen</h4>
              <p>Pelastussuunnitelma käydään läpi rakennuksen haltijan, omistajan tai muun edustajan kanssa.</p>

              <h4>Perehdytys</h4>
              <p>Pelastussuunnitelma perehdytetään rakennuksessa asuville, oleskeleville ja siellä työskenteleville henkilöille.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/jarjestyksenvalvonta">Järjestyksenvalvontapalvelut</Link>
          <Link to="/vartiointi">Vartiointipalvelut</Link>
          <Link to="/muutpalvelut/radiopuhelin">Radiopuhelimet</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage23WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_3_1;
