import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQService2x';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage22WhyChoose from '../../components/ServicePage22WhyChoose';
import ServicePageForm from '../../components/ServicePageForm';
import Testimonial from '../../components/Testimonial';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_1_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
          <h2>Vartiointipalvelut</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50 text-center" id="content">
          <Row >
            <Col sm={{ span: 8, offset: 2}}>
              <h1>TYÖMAA- JA TEOLLISUUSVARTIOINTI</h1>
              <h3>LUOTETTAVAA JA AMMATTIMAISTA VARTIOINTIPALVELUA</h3>
              <p>
                Tuotamme vartiointipalveluita erilaisten ja eri kokoisten työmaiden sekä teollisuuslaitosten ja -alueiden tarpeisiin. Tavanomaisempien tehtävien ohessa, kiinnitämme erityistä huomiota kulunvalvontaa, työ- ja paloturvallisuuteen ja omaisuuden suojaan. Huomioimme toiminnassamme myös erilaiset työterveyteen ja työympäristöön liittyvät riskit. Tarjoamme vartijoillemme myös erityistä koulutusta erilaisten riskien havainnoimiseen ja niihin puuttumiseen..
              </p>

              <h4>Miksi vartiointipalvelut ovat tärkeitä?</h4>
              <p className="p-b-50">
                Vartiointipalvelu on tehokas tapa parantaa työmaan ja teollisuuslaitoksen tai -alueen turvallisuutta sekä erilaisiin riskeihin varautumista. Vartiointipalvelua voi hyödyntää monin tavoin, ja se on kustannustehokas tapa parantaa alueen työturvallisuuden valvontaa sekä ylläpitää erilaisten työmaiden tai teollisuuslaitosten toiminnan kannalta tärkeiden järjestelmien toimivuutta. Vartijat raportoivat havainnoistaan ajantasaisesti, mikä mahdollistaa toiminnan häiriöiden ja vikojen ennaltaehkäisyn ennen suurempien vahinkojen syntymistä. Lisäksi vartiointipalvelun avulla voidaan parannetaan omaisuuden suojaa rikollista toimintaa vastaan.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray">
          <Row>
            <Col>
              <Container className="bg-white p-b-25 p-t-25 text-center">
                <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                <NewReferences references={['noho', 'finlandia', 'rajupaja', 'kmarket', 'ksupermarket', 'eurooppa', 'familar', 'humana', 'lauste']} />
              </Container>
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>Kulunvalvonta</h4>
              <p>Vastaanotto- ja kulkulupa asiat, logistiikan ohjaus, asiakaspalvelu.</p>

              <h4>Vastaanotto- ja postituspalvelu</h4>
              <p>Asiakas- ja vierailija sähköpostien hallinta, sisäisen ja ulkoisen postin käsittely.</p>

              <h4>Henkilöturvallisuus</h4>
              <p>Työ- ja henkilöturvallisuuden valvonta, tapaturmien ja onnettomuuksien ennaltaehkäisy ja estäminen, puhallutukset.</p>

              <h4>Omaisuuden suojaaminen</h4>
              <p>Aineellisen ja aineettoman omaisuuden suojaaminen erilaisilta rikoksilta ja vahingonteolta, erilaisiin laitteisto- ja järjestelmähälytyksiin vastaaminen kohteessa, poistumistarkastukset.</p>

              <h4>Aluevartiointi</h4>
              <p>Aluevartiointi jalan tai ajoneuvolla, tarkastuskierrokset, avaus- ja sulkukierrokset, työ-, palo- ja pelastusturvallisuuden valvonta, hälytysjärjestelmien aktivointi, hälytyksiin vastaaminen.</p>

              <h4>Avainhallinta</h4>
              <p>Työmaan tai teollisuusalueen avainhallinta ja oven avaukset.</p>

              <h4>Tekninen valvonta</h4>
              <p>Kameravalvonta ja kameravalvonnan suunnittelu, rikos-, palo- ja LVIS -järjestelmien valvonta.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/asiantuntijapalvelut/turvallisuusvalvoja">Turvallisuusvalvoja</Link>
          <Link to="/asiantuntijapalvelut/hse-valvoja">HSE-Valvoja</Link>
          <Link to="/muutpalvelut/alkometrit">Alkometrit</Link>
          <Link to="/muutpalvelut/radiopuhelin">Radiopuhelimet</Link>
          <Link to="/muutpalvelut/ensiapu">Ensiapu</Link>
          <Link to="/muutpalvelut/defibrillaattori">Defibrilaattorit</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage22WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_1_1;
