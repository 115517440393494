import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Banner from '../../components/Banner'
import { Link } from 'react-router-dom';
import './style.scss';

import service1 from './service1.jpg';
import service2 from './service2.jpg';
import service3 from './service3.jpg';
import service4 from './service4.jpg';

class ServicesPage extends Component {
  render() {
    return (
      <div className="page servicespage">
        <Banner>
          <h1>Palvelut</h1>
        </Banner>

        <Container>
          <Row>
            <Col>
            
          <Row className="text-center p-t-100">
            <Col sm={{ span: 8, offset: 2}}>
                <h2>Turvallisuuspalveluita monipuolisesti - suunnittelusta toteutukseen</h2>
                <p>
                  Tuotamme turvallisuuspalveluita valtakunnallisesti. Pääpaino palveluissamme on
                  järjestyksenvalvonnassa ja vartioinnissa, sekä niihin liittyvissä suunnitelu- ja
                  asiantuntijatehtävissä. Lisäksi järjestämme yksityisen turvallisuusalan koulutuksia sekä
                  tarjoamme asiakkaan toimintaan ja toimialaan liittyviä, erikseen räätälöityjä turvallisuus- ja
                  uhkatilannekoulutuksia.
                </p>

                <p>Kaikkea toimintaamme ohjaa <span className="highlight">High-Class - Confidential - Safe</span> periaate. Teemme aina parhaamme, jotta tuottamamme palvelu olisi korkea laatuista, luotettavaa ja turvallista.</p>
            </Col>
          </Row>

          <Row className="p-t-100">
            <Col md={{span: 5, order: 1}} className="pr-md-4 mb-4">
              <img src={service1} alt="Järjestyksenvalvonta" className="img-fluid" />
            </Col>
            <Col md={{span: 7, order: 2}}>
              <h4>JÄRJESTYKSENVALVONTA</h4>
              <h3>Järjestyksenvalvontapalvelut</h3>
              <p>
                Tuotamme järjestyksenvalvontapalveluita
                ravintoloihin, tapahtumiin, leirintäalueille,
                meriliikenteen matkustaja-aluksille,
                kauppakeskuksiin, satamiin sekä julkisen
                liikenteen liikenneasemille ja kulkuneuvoihin.
              </p>

              <p>
                Toiminnassamme panostamme henkilöstömme
                laatuun, koulutukseen sekä ammattimaiseen ja
                asiakaspalvelukeskeiseen tehtävien hoitamiseen.
                Oikea henkilöstö oikeissa tehtävissä ja selkeät
                tavoitteet, luovat jatkumoa ja luottamusta
                asiakkaissamme.
              </p>

              <p className="readMore">
                <Link className="btn-standard" to="/jarjestyksenvalvonta">Lue lisää</Link>
              </p>
            </Col>
          </Row>

          <Row className="p-t-100">
            <Col md={{span: 5, order: 2}} className="pl-md-4 mb-4">
              <img src={service2} alt="Järjestyksenvalvonta" className="img-fluid" />
            </Col>

            <Col md={{span: 7, order: 1}}>
              <h4>VARTIOINTI</h4>
              <h3>Vartiointipalvelut</h3>
              <p>
                Tuotamme vartiointipalveluita myymälä- ja
                liikekiinteistöihin, sosiaali- ja terveysalan
                toimipisteisiin, teollisuuteen ja työmaille.
                Tarjoamme myös aula- ja vastaanottopalveluita
                sekä henkilöturvallisuuteen ja
                rikostenpaljastamiseen liittyviä palveluita.

              </p>

              <p>
                Toiminnassamme panostamme
                ennaltaehkäisevään ja estävään toimintaan.
                Otamme huomioon asiakkaidemme ainutlaatuiset
                turvallisuustarpeet räätälöidäksemme juuri heille
                sopivat vartiointi ja turvallisuus ratkaisut.
              </p>

              <p className="readMore">
                <Link className="btn-standard" to="/vartiointi">Lue lisää</Link>
              </p>
            </Col>
          </Row>

          <Row className="p-t-100">
            <Col md={{span: 5, order: 1}} className="pr-md-4 mb-4">
              <img src={service3} alt="Järjestyksenvalvonta" className="img-fluid" />
            </Col>

            <Col md={{span: 7, order: 2}}>
              <h4>KONSULTOINTI JA SUUNNITTELU</h4>
              <h3>Asiantuntijapalvelut</h3>
              <p>
                Tarvitsetko räätälöityä turvaa toiminnallesi?
                Suunnittelemme ja toteutamme yksilöllisiä, juuri
                sinun tarpeidesi mukaisia turvallisuusratkaisuja.
              </p>

              <p>
                Asiantuntijapalvelumme on suunniteltu tukemaan
                asiakkaidemme päivittäistoimintaa.
                Turvallisuuden antaminen ammattitaitoisiin ja
                asiantunteviin käsiin mahdollistaa asiakkaan
                keskittymisen oman päivittäistoiminnan
                pyörittämiseen, eikä arki kulu turvallisuusasioista
                murehtimiseen.
              </p>

              <p className="readMore">
                <Link className="btn-standard" to="/asiantuntijapalvelut">Lue lisää</Link>
              </p>
            </Col>
          </Row>

          <Row className="p-t-100">
            <Col md={{span: 5, order: 2}} className="pl-md-4 mb-4">
              <img src={service4} alt="Järjestyksenvalvonta" className="img-fluid" />
            </Col>

            <Col md={{span: 7, order: 1}}>
              <h4>MUUT</h4>
              <h3>Muut palvelumme</h3>
              <p>
                Saat kauttamme myös lukuisia muita
                turvallisuuden lisäpalveluita ja oheistuotteita,
                jotka on tarkoitettu tukemaan asiakkaidemme
                päivittäistoimintaa sekä tuottamaan heidän
                toiminnalleen lisäarvoa ja turvaa.
              </p>

              <p className="readMore">
                <Link className="btn-standard" to="/muutpalvelut">Lue lisää</Link>
              </p>
            </Col>
          </Row>

          </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ServicesPage;
