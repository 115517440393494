import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom';

class ServicePageWhyChoose extends Component {
  render() {
    return (
      <Container fluid className="bg-gray">
        <Row>
          <Col>
            <Container>
              <Row>
                <Col sm={{ span: 8, offset: 2}} className="p-t-50 p-b-50">
                  <h3 className="p-b-25">Miksi valita Nordic Security Alliance palveluntuottajaksi?</h3>

                  <h4>Asiantuntijapalveluidemme monialaisuus</h4>
                  <p>Asiantuntijatiimimme koostuu eri aloilta ja taustoista tulevista ammattilaisista, jotka jakavat yhteisen tavoitteen turvallisemmasta tulevaisuudesta. Monialaisuus luo laajan ammatti- ja kokemuspohjan erilaisiin asiakastarpeisiin.</p>

                  <p>Ymmärrämme, että jokainen asiakas on ainutlaatuinen. Siksi suunnittelemme ja toteutamme palvelumme vastaamaan juuri sinun tarpeitasi ja toimintaympäristöäsi.</p>

                  <h4>Yhteistyössä kohti turvallisempaa tuleivaisuutta</h4>
                  <p>Olemme ylpeitä voidessamme työskennellä tiiviisti asiakkaidemme kanssa. Asiakkaan ongelmat ja huolet turvallisuusasioissa on meille sydänasia ja pyrimme aina tarjoamaan ratkaisuvaihtoehtoja näiden poistamiseksi. Otamme aina huomioon asiakkaidemme ainutlaatuiset turvallisuutarpeet räätälöidäksemme juuri heille sopivat turvallisuus ratkaisut. Tarvittaessa otamme mukaan eri sidosryhmien ammattilaisia tai yhteistyökumppaneita oman toimintamme tueksi.</p>

                  <h4>Ammattitaitoinen ja asiakaspalvelukeskeinen henkilökunta</h4>
                  <p>
                    Laaja, alansa ammattilaisista koostuva joukkomme on meille ylpeyden aihe ja merkittävin ero muihin alan toimijoihin maassamme. Oikea henkilöstö oikeissa tehtävissä sekä selkeät tavoitteet luovat jatkuvuutta ja luottamusta asiakkaisemme keskuudessa.
                    <br/>
                    <span class="highlight">High-Class - Confidential - Safe</span> -periaatteemme heijastuvat kaikessa tekemisessämme, aina.
                  </p>

                  <h4>Luovuta turvallisuutesi asiantunteviin käsiin</h4>
                  <p>Valitsemalla asiantuntijapalvelumme valitset kumppanin, joka välittää turvallisuudestasi ja tarjoaa kattavia, innovatiivisia ja räätälöityjä ratkaisuja, jotka vastaavat muuttuviin tarpeisiisi. Seuraamme jatkuvasti alan kehitystä, jotta meillä olisi aina ajantasaisin tietotaito ja ratkaisuvaihtoehdot ongelmiisi. Luottamalla päivittäistoimintasi turvallisuuden kokonaan tai osittain käsiimme, voit huoletta keskittyä muihin toimintasi kannalta tärkeisiin osa-alueisiin.</p>

                  <h4>Ota yhteyttä jo tänään niin katsotaan yhdessä, kuinka voimme palvella sinua parhaiten.</h4>
                </Col>
              </Row>

              <Row>
                <Col sm={{ span: 8, offset: 2}} className="p-b-100 text-center">
                  <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ServicePageWhyChoose;