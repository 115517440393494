import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom';

class ServicePageWhyChoose extends Component {
  render() {
    return (
      <Container fluid className="bg-gray">
        <Row>
          <Col>
            <Container>
              <Row>
                <Col sm={{ span: 8, offset: 2}} className="p-t-50 p-b-50">
                  <h3 className="p-b-25">Miksi valita Nordic Security Alliance palveluntuottajaksi?</h3>

                  <h4>Järjestyksenvalvontapalveluidemme asiakaslähtöisyys ja ammattimaisuus</h4>
                  <p>Panostamme korkealaatuiseen turvallisuuteen tarjoamalla asiakkaillemme asiakaslähtöistä ja ammattimaista järjestyksenvalvontaa.</p>

                  <p>Asiakaslähtöisyytemme perustuu tiiviiseen yhteistyöhön asiakkaidemme kanssa. Kuuntelemme tarpeitanne herkällä korvalla ja varmistamme, että järjestyksenvalvontapalvelumme on juuri sellaista kun toivotte.</p>

                  <p>Ammattimaisuus on toimintamme kulmakivi. Henkilöstömme koostuu kokeneista ja koulutetuista järjestyksenvalvonnan ammattilaisista. Olemme sitoutuneet jatkuvasti kehittämään osaamistamme ja seuraamaan alan uusimpia trendejä varmistaaksemme, että pystymme tarjoamaan aina ajanmukaista ja laadukasta palvelua. Ammattitaitomme ja toimintamme läpinäkyvyys luovat vankan perustan asiakkaidemme luottamukselle.</p>

                  <h4>Yhteistyössä kohti turvallisempaa tuleivaisuutta</h4>
                  <p>Olemme ylpeitä voidessamme työskennellä tiiviisti asiakkaidemme kanssa. Asiakkaan ongelmat ja huolet turvallisuusasioissa on meille sydänasia ja pyrimme aina tarjoamaan ratkaisuvaihtoehtoja näiden poistamiseksi. Otamme aina huomioon asiakkaidemme ainutlaatuiset turvallisuutarpeet räätälöidäksemme juuri heille sopivat järjestyksenvalvonta ja turvallisuus ratkaisut. Tarvittaessa otamme mukaan eri sidosryhmien ammattilaisia tai yhteistyökumppaneita oman toimintamme tueksi.</p>      

                  <h4>Ammattitaitoinen ja asiakaspalvelukeskeinen henkilökunta</h4>
                  <p>
                    Laaja, alansa ammattilaisista koostuva joukkomme on meille ylpeyden aihe ja merkittävin ero muihin alan toimijoihin maassamme. Oikea henkilöstö oikeissa tehtävissä sekä selkeät tavoitteet luovat jatkuvuutta ja luottamusta asiakkaisemme keskuudessa.
                    <br/>
                    <span class="highlight">High-Class - Confidential - Safe</span> -periaatteemme heijastuvat kaikessa tekemisessämme, aina.
                  </p>

                  <h4>Valitsemalla järjestyksenvalvontapalvelumme edistät omaa menestystäsi</h4>
                  <p>Menestyksesi on meille tärkeää. Valitsemalla järjestyksenvalvontapalvelumme varmistat toimintasi ja toimipaikkasi turvallisuuden, viihtyvyyden ja miellyttävän asiakaskokemuksen asiakkaillesi. Järjestyksenvalvonnalla on suuri rooli asiakaskokemuksen muodostumisessa. Ei ole meiltä pois, että asiakkaasi on tyytyväinen ja viihtyy, päinvastoin. Asiakkaittesi tyytyväisyys on myös meidän etumme.</p>

                  <p>Ihmisläheisellä ja ammattimaisella järjestyksenvalvontapalvelulla tuemme menestystäsi. Voit huoletta keskittyä muihin tärkeisiin asioihin, kun me huolehdimme järjestyksestä ja turvallisuudesta.</p>

                  <h4>Ota yhteyttä jo tänään niin katsotaan yhdessä, kuinka voimme palvella sinua parhaiten.</h4>
                </Col>
              </Row>

              <Row>
                <Col sm={{ span: 8, offset: 2}} className="p-b-100 text-center">
                  <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ServicePageWhyChoose;