import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Banner from '../../components/Banner'
// import Feature from '../../components/Feature'
import ContactPerson from '../../components/ContactPerson'
import pfp from './jyrki.varvas.jpg';

// import './App.scss';

class HomePage extends Component {
  componentDidMount() {
    const script = document.createElement('script');

    script.src = 'https://nordicsecurityalliance.activehosted.com/f/embed.php?id=1';
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    return (
      <div className="page homepage">       
        <Banner>
            <h2>Luomme turvallisuutta</h2>
        </Banner>
        <Container className="intro p-t-100 p-b-100 text-center">
            <Row>
                <Col sm={{ span: 8, offset: 2}}>
                    <h1>Pyydä tarjous - kartoitamme tilanteesi</h1>
                    <p>
                        Jättämällä meille tarjouspyynnön, olemme teihin yhteydessä 3 arkipäivän kuluessa.
                        Tarjouspyynnön ei tarvitse olla täydellinen, sillä kartoitamme tilanteesi ja tarpeesi
                        yhteydenoton yhteydessä. Meille riittää, että osaat kertoa millaiselle palvelulle teillä
                        mahdollisesti olisi tarve.
                    </p>
                    <p>
                        Halutessasi voit olla yhteydessä suoraan myyntiimme sähköpostitse <a href="mailto:myynti@nordicsec.fi">myynti@nordicsec.fi</a>.
                        Mikäli asiasi on kiireellinen, voit myös soittaa myyntiimme.
                    </p>
                    <br/><br/>
                    <ContactPerson name="Jyrki Varvas" title="Myyntipäällikkö" phone="+358 (0)41 544 6861" email="jyrki.varvas@nordicsec.fi" image={pfp} />
                </Col>
            </Row>
        </Container>

        <Container className="p-t-50 p-b-50 text-center">
          <Row>
            <Col>
              <h2>Tarjouspyyntö</h2>

              <div class="_form_1"></div><script src="https://nordicsecurityalliance.activehosted.com/f/embed.php?id=1" type="text/javascript" charset="utf-8"></script>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default HomePage;
