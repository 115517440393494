import React, { Component } from 'react';
import Banner from '../../components/Banner'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Link from 'react-router-dom/Link'
// import Button from 'react-bootstrap/Button'
import './style.scss';

class RecruitmentListPage extends Component {

  constructor(props){
    super(props)
  }

  componentDidMount() {
    const script = document.createElement('script');
    
    script.src = 'https://nordicsecurityalliance.activehosted.com/f/embed.php?id=3';
    script.async = true;
    document.body.appendChild(script);
  }
  
  render() {
    return (
      <div className="page employeepage">

        <Banner>
            <h1>Työnhakijalle</h1>
        </Banner>

        <Container className="intro p-t-50 p-b-50 text-center">
            <Row>
                <Col sm={{ span: 8, offset: 2}}>
                    <h1>Liity suomen suurimmalle yksityiselle turvallisuusalan rekrylistalle</h1>
                    <p>Haemme jatkuvast uusia työntekijöitä. Liittymällä sähköpostilistallemme saat aina ensimmäisenä tiedon uusista avoimista työpaikoista</p>
                </Col>
            </Row>
        </Container>

        <Container className="p-t-50 p-b-50 bg-gray">
          <Row>
            <Col sm={{offset: 2, span: 8}}>
              <div className="_form_3"></div>
            </Col>
          </Row>
        </Container>
      
      </div>
    );
  }
}

export default RecruitmentListPage;
