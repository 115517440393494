import React, { Component } from 'react';
import { Children } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './style.scss';

class ServiceHeader extends Component {
  render() {
    return (
      <Container className="service-page-other-links">
        <Row>
          <Col sm={{ span: 8, offset: 2 }}>
            <h3>Liitettävissä olevat lisäpalvelut</h3>
            
            <ul>
              {
                Children.map(this.props.children, (child, i) => {
                  return <li key={i}>{child}</li>;
                })
              }
            </ul>
          </Col>
        </Row>
    </Container>
    );
  }
}

export default ServiceHeader;
