import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class FAQ extends Component {
  render() {
    return (
      <Container fluid className="p-b-50 p-t-50">
        <Row>
          <Col>
            <Container>
              <Row>
                <Col sm={{ span: 8, offset: 2 }}>
                  <h3>Meiltä usein kysyttyä:</h3>

                  <h4>Mikä on järjestyksenvalvontapalveluidemme hintataso?</h4>
                  <p>Hinta riippuu täysin asiakkaan tarpeesta, toimialasta, tehtävän vaativuudesta sekä henkilöstön koulutusvaatimuksista. Myös ostettavan palvelun volyymi vaikuttaa hintaan. Parhaiten pystymme vastaamaan kun olemme kartoittaneet tilanteesi.</p>

                  <h4>Mikä on pora järjestyksenvalvoja?</h4>
                  <p>Pora järjestyksenvalvoja nimitystä käytetään poliisin- tai rajavartiolaitoksen avuksi asetetusta järjestyksenvalvojasta. Pora järjestyksenvalvojalla on sekä järjestyksenvalvojan ja vartijan koulutus. Lisäksi pora järjestyksenvalvojan työasu ja tunnukset poikkeavat tavanomaisesta tapahtumien ja ravintoloiden järjestyksenvalvonnasta. Pora järjestyksenvalvoja voidaan asettaa paikkoihin, jossa järjestyksen ja turvallisuuden ylläpitäminen ei muulla tavalla ole tarkoituksenmukaista sekä järjestyksen ja turvallisuuden ylläpitäminen sitä perustellusta syystä edellyttää. Järjestyksenvalvojan asettamiseen vaaditaan lupa ja sitä haetaan paikalliselta poliisilaitokselta.</p>

                  <h4>Mikä on pora järjestyksenvalvojien palkkaus?</h4>
                  <p>Noudatamme palkkauksessa vartiointialan työehtosopimusta.</p>

                  <h4>Millainen on järjestyksenvalvojienne koulutus?</h4>
                  <p>Järjestyksenvalvojillamme ovat suorittaneet vähintään sisäministeriön vaatimukset täyttävän järjestyksenvalvojan peruskoulutuksen sekä järjestyksenvalvojan voimankäyttöön liittyvän fyysisen voimankäytön perusteet. Lisäksi järjestämme järjestyksenvalvojillemme säännöllistä voimankäyttökoulutusta, voimankäyttövälineisiin liittyvää lisäkoulutusta, skenaario harjoituksia sekä palo-, pelastus- ja ensiapukoulutusta. Lisäksi annamme työntekijöillemme kattavan perehdytyksen työtehtäviin ja erilaisiin asiakapalvelu tilanteisiin.</p>
                  <p>Poliisin tai rajavartiolaitoksen avuksi asetetulla järjestyksenvalvojalla on lisäksi myös vartijan koulutus.</p>

                  <h4>Kuinka nopeasti järjestyksenvalvontapalvelumme on saatavilla?</h4>
                  <p>Tilanteet saattavat välillä yllättää, jolloin järjestyksenvalvontapalvelun saamisella voi olla kiire. Yhteydenoton jälkeen aluevastaavamme tarkistaa alueellisen resurssin ja ilmoittaa asiakkaalle tilanteen. Teemme aina parhaamme, jotta pystyisimme vaastaamaan myös yllättäviin ja kiireellisiin tilanteisiin.</p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FAQ;
