import React, { Component } from 'react';


class Feed extends Component {

/* <link href="https://assets.juicer.io/embed.css" media="all" rel="stylesheet" type="text/css" />
<ul class="juicer-feed" data-feed-id="nordicsec">
<h1 class="referral">
<a href="https://www.juicer.io">Powered by Juicer</a>
</h1>
</ul> */

    componentDidMount() {
        const style = document.createElement('link');
        style.setAttribute('href', 'https://assets.juicer.io/embed.css')
        style.setAttribute('media', 'all')
        style.setAttribute('rel', 'stylesheet')
        style.setAttribute('type', 'text/css')
        document.body.appendChild(style);

        const script = document.createElement('script');
        script.setAttribute(
          'src', 
          'https://assets.juicer.io/embed.js');
        // script.addEventListener('load', () => {
        //   EmbedManager.embed({
        //     key: "key",
        //     width: "100%",
        //     mobileResponsive: true
        //   });
        // });
        document.body.appendChild(script);
      }

    render() {
        return (
          <div >
            <div style={{textAlign: 'center'}}>
            <h2>Julkaisut</h2>
            </div>            
              
            <ul className="juicer-feed" data-feed-id="nordicsec-49cba8db-f3db-4c96-aaaa-675164d439a2" data-style="widget"></ul>
            <div style={{textAlign: 'center'}}>
              <a href="https://www.facebook.com/NordicSec" target="_blank" rel="noopener noreferrer" className="btn-blue">Katso lisää Facebookissa</a>
              </div>
          </div>
        )
      }
}

export default Feed;
