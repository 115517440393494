import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './style.scss';

import allasImg from './www.allasrestaurants.fi.png'
import rumaImg from './www.anniskeluravintolaruma.fi.png'
import babylonImg from './www.babylonhki.fi.png'
import fredrikImg from './www.barfredrik.fi.png'
import escapeImg from './www.escapejkl.fi.jpg'
import eurooppaImg from './www.eurooppamarkkinat.fi.png'
import familarImg from './www.familar.fi.jpg'
import finlandiaImg from './www.finlandiatalo.fi.svg'
import henkkaImg from './www.henkkatampere.fi.png'
import hollywoodImg from './www.hollywood.fi.jpg'
import humanaImg from './www.humana.fi.png'
import jokeritImg from './www.jokerit.fi.png'
import jtImg from './www.qmg.fi.png'
import kaivoImg from './www.kaivohuone.fi.jpg'
import komediaImg from './www.komediateatteri.fi.png'
import kmarketImg from './www.k-ruoka.fi.market.png'
import ksupermarketImg from './www.k-ruoka.fi.supermarket.png'
import casinoImg from './www.kulosaarencasino.fi.png'
import kulttuuriImg from './www.kulttuurikasarminravintolat.fi.jpg'
import lausteImg from './www.lauste.fi.svg'
import londonImg from './www.londonjkl.fi.jpg'
import loylyImg from './www.loylyhelsinki.fi.png'
import mangoImg from './www.mangodiscobar.fi.jpg'
import maxineImg from './www.maxine.fi.png'
import moominImg from './www.moominworld.fi.png'
import nohoImg from './www.noho.fi.png'
import oldIrishImg from './www.oldirishpub.fifipageetusivu.png'
import paappaImg from './www.paappa.fi.jpg'
import porssiKlubiImg from './www.porssiklubi.fi.png'
import rajupajaImg from './www.rajupaja.fi.png'
import porssiTaloImg from './www.restaurantporssi.fi.png'
import ilonaImg from './www.sinunilona.fi.jpg'
import joleneImg from './www.tulikuuma.fi-restaurant-jolene-tampere.png'
import vanhaImg from './www.vanhaylioppilastalo.fi.png'

const referenceLinks = {
  allas: {image: allasImg, link: 'https://www.allasrestaurants.fi/'},
  ruma: {image: rumaImg, link: 'https://anniskeluravintolaruma.fi/'},
  babylon: {image: babylonImg, link: 'https://www.babylonhki.fi/'},
  fredrik: {image: fredrikImg, link: 'https://www.barfredrik.fi/'},
  escape: {image: escapeImg, link: 'https://www.escapejkl.fi/'},
  eurooppa: {image: eurooppaImg, link: 'https://www.eurooppamarkkinat.fi/'},
  familar: {image: familarImg, link: 'https://familar.fi/'},
  finlandia: {image: finlandiaImg, link: 'https://www.finlandiatalo.fi/'},
  henkka: {image: henkkaImg, link: 'https://henkkatampere.fi/'},
  hollywood: {image: hollywoodImg, link: 'https://www.hollywood.fi/'},
  humana: {image: humanaImg, link: 'https://www.humana.fi/'},
  jokerit: {image: jokeritImg, link: 'https://www.jokerit.fi/'},
  jt: {image: jtImg, link: 'https://qmg.fi/'},
  kaivo: {image: kaivoImg, link: 'https://www.kaivohuone.fi/'},
  komedia: {image: komediaImg, link: 'https://komediateatteri.fi/'},
  kmarket: {image: kmarketImg, link: 'https://www.k-ruoka.fi/'},
  ksupermarket: {image: ksupermarketImg, link: 'https://www.k-ruoka.fi/'},
  casino: {image: casinoImg, link: 'https://www.kulosaarencasino.fi/'},
  kulttuuri: {image: kulttuuriImg, link: 'https://www.kulttuurikasarminravintolat.fi/'},
  lauste: {image: lausteImg, link: 'https://lauste.fi/'},
  london: {image: londonImg, link: 'https://londonjkl.fi/'},
  loyly: {image: loylyImg, link: 'https://www.loylyhelsinki.fi/'},
  mango: {image: mangoImg, link: 'https://mangodiscobar.fi/'},
  maxine: {image: maxineImg, link: 'https://maxine.fi/'},
  moomin: {image: moominImg, link: 'https://www.moominworld.fi/'},
  noho: {image: nohoImg, link: 'https://www.noho.fi/'},
  oldIrish: {image: oldIrishImg, link: 'https://oldirishpub.fi/fi/page/etusivu'},
  paappa: {image: paappaImg, link: 'https://paappa.fi/'},
  porssiKlubi: {image: porssiKlubiImg, link: 'https://www.porssiklubi.fi/'},
  rajupaja: {image: rajupajaImg, link: 'https://rajupaja.fi/'},
  porssiTalo: {image: porssiTaloImg, link: 'https://www.restaurantporssi.fi/'},
  ilona: {image: ilonaImg, link: 'https://sinunilona.fi/'},
  jolene: {image: joleneImg, link: 'https://www.tulikuuma.fi/restaurant/jolene-tampere/'},
  vanha: {image: vanhaImg, link: 'https://www.vanhaylioppilastalo.fi/'}
}

const NewReferences = (props) => {
  const [page, setPage] = useState(0);
  const [mobile, setMobile] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const referencesRef = React.createRef();

  function updateDimensions() {
    setMobile(window.innerWidth < 1024);
    setNumPages(window.innerWidth < 1024 ? props.references.length : Math.ceil(props.references.length / 5));
  }

  function scrollToPage(p) {
    if(p < 0) {
      p = 0;
    } else if(p >= numPages) {
      p = numPages - 1;
    }

    setPage(p);
    referencesRef.current.scrollTo({
      left: referencesRef.current.clientWidth * p,
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  let references;

  if(mobile) {
      references = props.references.map((reference, index) => {
          return <div class="page">
              <a className="reference" href={referenceLinks[reference].link} style={{backgroundImage: `url(${referenceLinks[reference].image})`}}></a>
          </div>
      });
  } else {
      // Split references into chunks of 5 per page for desktop
      let referencePages = [];
      let chunk = [];

      props.references.forEach((reference, index) => {
        chunk.push(reference);

          if(chunk.length === 5) {
              referencePages.push(chunk);
              chunk = [];
          }
      });

      referencePages.push(chunk);
        
      references = referencePages.map((page, index) => {
          return <div className="page">
          {page.map((reference, index) => {
              return <a className="reference" href={referenceLinks[reference].link} style={{backgroundImage: `url(${referenceLinks[reference].image})`}}>{referenceLinks[reference].link}</a>
          })}
          </div>
      });
  }

  return (
    <Container className="text-center">
      <Row>
        <Col>
          <div className="reference-carousel">
            <div className={"arrow left" + (page === 0 ? ' disabled' : '')} onClick={() => {scrollToPage(page - 1)}}>&#10094;</div>
            <div className={"arrow right" + (page === numPages - 1 ? ' disabled' : '')} onClick={() => {scrollToPage(page + 1)}}>&#10095;</div>

            <div className="references" ref={referencesRef}>
              {references}
            </div>

            <div className="reference-carousel-dots">
              {(numPages > 0) && Array.from(Array(numPages).keys()).map((p, index) => {
                return <span className={"dot" + (page === p ? ' active' : '')} onClick={() => {scrollToPage(page)}}></span>
              })}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

NewReferences.defaultProps = {
  references: []
};

export default NewReferences