import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ServiceHeader from '../../components/ServiceHeader';
import FAQ from '../../components/FAQService1';
import ServicePage21WhyChoose from '../../components/ServicePage21WhyChoose';
import ServicePageForm from '../../components/ServicePageForm';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_1 extends Component {


  render() {
    return (
      <div className="page homepage">       
        <Banner> 
            <h2>Järjestyksenvalvontapalvelut</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50 text-center" id="content">
            <Row>
                <Col sm={{ span: 8, offset: 2}}>
                    <h1>JÄRJESTYKSENVALVONTA&shy;PALVELUT</h1>
                    <h3>TURVALLISUUTTA JA ASIAKASPALVELUA AMMATTITAIDOLLA</h3>
                    <p>
                      Turvallisuusympäristömme muutokset ovat johtaneet siihen, että
                      järjestyksenvalvontapalveluista on tullut entistä tärkeämpi ja näkyvämpi osa pävittäistä
                      elämäämme. Järjestyksenvalvontapalvelut ovat merkittävässä roolissa erityisesti palvelutoimialojen, kauppakeskusten, yksityis- ja yleisötilaisuuksien sekä joukkoliikenteen ja julkisensektorin järjestyksen ja turvallisuuden ylläpitämisessä. Myös järjestyksenvalvojie ammattitaito- ja muut laatuvaatimukset ovat lisääntyneet.
                    </p>

                    <h4>Miksi järjestyksenvalvontapalvelut ovat tärkeitä?</h4>
                    <p className="p-b-50">
                      Järjestyksenvalvonta mahdollistaa häiriöttömän toiminnan. Ammattitaitoisella, luotettavalla ja
                      asiakaspalvelukeskeisellä järjestyksenvalvontapalvelulla luot miellyttävän ja viihtyisän
                      ilmapiirin niin majoitus- ja ravitsemisliikkeisiin, yksityis- ja yleisötilaisuuksiin, leirintäalueille, meriliikenteen matkustaja-aluksille, joukkolliikenteeseen ja muihin julkisiin tiloihin.
                      Järjestyksenvalvojamme ovat koulutettuja toimimaan kaikissa tilanteissa ennaltaehkäisevästi
                      ja estävästi, turvallisuutta ja ihmisten välistä sovinnollisuutta edistäen.
                    </p>

                    <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </Col>
            </Row>
          </Container>

          <Container fluid className="p-b-25 p-t-25 bg-gray">
            <Row>
              <Col>
                <Container className="bg-white p-b-25 p-t-25 text-center">
                  <h3>Myös he luottavat meihin turvallisuusasioissa:</h3>
                  <NewReferences references={['noho', 'finlandia', 'oldIrish', 'rajupaja', 'moomin', 'porssiTalo', 'vanha', 'allas', 'komedia', 'kulttuuri', 'ilona', 'london', 'escape']} />
                </Container>
              </Col>
            </Row>
          </Container>

          <Container className="p-t-25">
            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <h3>Palvelumme - Turvallisuutta ja asiakastyytyväisyyttä</h3>
                <p>Järjestyksenvalvontapalveluissamme keskitymme turvallisuuteen ja asiakastyytyväisyyteen. Järjestyksenvalvojamme tarkkailevat jatkuvasti toimintaympäristöään ja puuttuvat toimialueellaan syntyviin tai jo käynnissä oleviin riita- ja ongelmatilanteisiin. Toimimme tehtävissämme neuvottelevalla, toisia kunnioittavalla ja sovinnollisuutta edistävällä tavalla.</p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Ravintolaturvallisuus" link="/jarjestyksenvalvonta/ravintolaturvallisuus" />
                <p>Järjestyksenvalvonnalla varmistat ravintolasi turvallisuuden ja viihtyvyyden, olipa kyseessä sitten hotelli, ruoka- tai viihderavintola, tai yön pikkutunneille auki oleva pubi tai yökerho.</p>
                <p>Järjestyksenvalvojamme toimivat toimipaikkasi käyntikorttina, sillä ovimies on usein ensimmäinen ja viimeinen henkilö, jonka asiakas toimipaikassasi kohtaa. Järjestyksenvalvojamme toimivat aina ammattimaisesti, asiakaspalvelua unohtamatta.</p>

                <br/>
                <p>
                  <Link className="btn-standard" to="/jarjestyksenvalvonta/ravintolaturvallisuus">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Tapahtumaturvallisuus" link="/jarjestyksenvalvonta/tapahtumaturvallisuus" />
                <p>Oli kyseessä yleisö- tai yksityistilaisuus, sisä- tai ulkoilmatapahtuma, yleinen kokous, messut, festivaalit urheilu- tai musiikkitapahtumat, varmistamme tapahtumasi turvallisen ja häiriöttömän toteutuksen. Tarvittaessa hoidamme turvallisuuden aina suunnittelusta tapahtuman turvalliseen läpivientiin ja avustamme viranomais- ja lupa-asioissa.</p>
                <p>Järjestyksenvalvojat ovat näkyvin osa tapahtumasi turvallisuutta. Siksi järjestyksenvalvojamme toimivat aina ryhdikkäästi ja asiakaslähtöisesti, luoden tapahtumaasi rennon ja viihtyisän ilmapiirin. Järjestyksenvalvojamme ovat koulutettuja huomioimaan erilaiset tapahtumaturvallisuuteen liittyvät riskit ja toimimaan tilanteissa ennaltaehkäisevästi ja estävästi.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/jarjestyksenvalvonta/tapahtumaturvallisuus">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Leirintäalueet" link="/jarjestyksenvalvonta/leirintaalue" />
                <p>Elämä leirintäalueella sujuu sopuisammin, kun alueella on oma järjestyksenvalvoja. Järjestyksenvalvoja ylläpitää alueen järjestystä ja turvallisuutta yhteistyössä muun henkilökunnan kanssa. Järjestyksenvalvoja turvaa myös alueella toimivien muiden palveluntarjoajien, kuten ravintoloiden, tapahtuma- ja aktiviteettipaikkojen turvallisuutta.</p>
                <p>Järjestyksenvalvojamme ovat koulutettuja ratkomaan erilaisia häiriötilanteita sekä valvomaan paloturvallisuutta. He ovat myös ensiaputaitoisia ja kykenevät toimimaan vaativissakin ensiaputehtävissä</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/jarjestyksenvalvonta/leirintaalue">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Matkustaja-alukset" link="/jarjestyksenvalvonta/matkustaja-alus" />
                <p>Meriliikenteen matkustaja-aluksien järjestyksenvalvonta on avainasemassa aluksen yleisen järjestyksen, turvallisuuden sekä matkustajien viihtyvyyden ylläpitämisessä. Viranomaistuki on usein kaukana tilanteissa, jotka vaativat välitöntä ennaltaehkäisevää tai estävää toimintaa.</p>
                <p>Järjestyksenvalvojamme ovat koulutettuja toimimaan erilaisissa häiriö- ja uhkatilanteissa, palo- ja pelastustoiminnassa sekä ensiaputilanteissa. He toimivat aina ammattimaisesti ja asiakaspalvelulähtöisesti, edistäen sovinnollisuutta ja turvallisuutta.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/jarjestyksenvalvonta/matkustaja-alus">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Joukkoliikenne ja liikenneasemat" link="/jarjestyksenvalvonta/joukkoliikennejaliikenneasemat" />
                <p>Järjestyksenvalvontapalvelut mahdollistavat turvallisen ja häiriöttömän ympäristön joukkoliikenteen kulkuneuvoissa ja liikenneasemilla. Järjestyksen ja turvallisuuden ylläpidon ohessa järjestyksenvalvojat myös opastavat toimialueella asioivia ihmisiä ja työskentelevät proaktiivisessa yhteistyössä alueen viranomaisten ja muiden toimijoiden kanssa.</p>
                <p>Järjestyksenvalvojamme ovat koulutettuja toimimaan aina ammattimaisesti, turvallisuutta ja sovinnollisuutta edistäen. Ihmisten turvallisuus ja hyvinvointi on aina korkein prioriteettimme. Tätä pyrimme edistämään etupainotteisella ennaltaehkäisevällä ja estävällä toiminnalla.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/jarjestyksenvalvonta/joukkoliikennejaliikenneasemat">Lue lisää</Link>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={{ span: 8, offset: 2}} className="p-b-50">
                <ServiceHeader title="Kauppakeskukset" link="/jarjestyksenvalvonta/kauppakeskus" />
                <p>Järjestyksenvalvonnalla ennaltaehkäiset häiriöiden ja ongelmien kroonistumista kauppakeskuksessa. Järjestyksenvalvontapalvelulla luot kauppakeskukseen turvallisen ja viihtyisän asiointiympäristön, mikä tukee asiakastyytyväisyyttä ja menestyvää liiketoimintaa</p>
                <p>Järjestyksenvalvojamme ovat helposti lähestyttäviä asiakaspalvelun ammattilaisia, jotka hallitsevat työskentelyn erilaisten ihmisryhmien kanssa. Järjestyksenvalvojat suorittavat toimenpiteensä aina ammattimaisesti, turvallisuutta ja sovinnollisuutta edistäen. He myös työskentelevät proaktiivisessa yhteistyössä alueen viranomaisten ja muiden toimijoiden kanssa.</p>
                <br/>
                <p>
                  <Link className="btn-standard" to="/jarjestyksenvalvonta/kauppakeskus">Lue lisää</Link>
                </p>
              </Col>
            </Row>
        </Container>

        <ServicePage21WhyChoose />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_1;
