import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class FAQ extends Component {
  render() {
    return (
      <Container fluid className="p-b-50 p-t-50">
        <Row>
          <Col>
            <Container>
              <Row>
                <Col sm={{ span: 8, offset: 2 }}>
                  <h3 className="p-b-25">Meiltä usein kysyttyä:</h3>

                  <h4>Mikä on järjestyksenvalvontapalveluidemme hintataso?</h4>
                  <p>Hinta riippuu täysin asiakkaan tarpeesta, toimialasta, tehtävän vaativuudesta sekä henkilöstön koulutusvaatimuksista. Myös ostettavan palvelun volyymi vaikuttaa hintaan. Parhaiten pystymme vastaamaan kun olemme kartoittaneet tilanteesi.</p>

                  <h4>Mitä järjestyksenvalvojan tehtäviin sisältyy?</h4>
                  <p>Järjestyksenvalvojat valvovat toimialueensa järjestystä ja turvallisuutta. Tehtävän sisältö riippuu siitä, missä järjestyksenvalvontaa suoritetaan.Tehtäviin voi esimerkiksi sisältyä yleisvalvontaa, hälytysvalvontaa, turvallisuustarkastuksia ja kulunvalvontaa. Myös erilaiset avustavat, turvallisuuteen oleellisesti liittyvät tehtävät ovat mahdollisia.</p>

                  <h4>Mikä on pora järjestyksenvalvoja?</h4>
                  <p>Pora järjestyksenvalvoja nimitystä käytetään poliisin- tai rajavartiolaitoksen avuksi asetetusta järjestyksenvalvojasta. Pora järjestyksenvalvojalla on sekä järjestyksenvalvojan ja vartijan koulutus. Lisäksi pora järjestyksenvalvojan työasu ja tunnukset poikkeavat tavanomaisesta tapahtumien ja ravintoloiden järjestyksenvalvonnasta. Pora järjestyksenvalvoja voidaan asettaa paikkoihin, jossa järjestyksen ja turvallisuuden ylläpitäminen ei muulla tavalla ole tarkoituksenmukaista sekä järjestyksen ja turvallisuuden ylläpitäminen sitä perustellusta syystä edellyttää. Järjestyksenvalvojan asettamiseen vaaditaan lupa ja sitä haetaan paikalliselta poliisilaitokselta.</p>

                  <h4>Mikä ero on vartijalla ja järjestyksenvalvojalla?</h4>
                  <p>Vartija vartioi toimeksiantajan henkilöstöä ja omaisuutta toimeksiantosopimuksessa määritellyllä vartioimisalueella, kun taas järjestyksenvalvoja valvoo yleistä järjestystä ja turvallisuutta sopimuksessa määritellyllä toimialueella.</p>

                  <h4>Kuinka nopeasti järjestyksenvalvontapalvelumme on saatavilla?</h4>
                  <p>Tilanteet saattavat välillä yllättää, jolloin järjestyksenvalvontapalvelun saamisella voi olla kiire. Yhteydenoton jälkeen aluevastaavamme tarkistaa alueellisen resurssin ja ilmoittaa asiakkaalle tilanteen. Teemme aina parhaamme, jotta pystyisimme vaastaamaan myös yllättäviin ja kiireellisiin tilanteisiin.</p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FAQ;
