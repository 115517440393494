import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom';

class ServicePageWhyChoose extends Component {
  render() {
    return (
      <Container fluid className="bg-gray">
        <Row>
          <Col>
            <Container>
              <Row>
                <Col sm={{ span: 8, offset: 2}} className="p-t-50 p-b-50">
                  <h3>Miksi valita Nordic Security Alliance palveluntuottajaksi?</h3>

                  <h4>Vartiointipalveluidemme Laatu ja Luotettavuus</h4>
                  <p>
                    Onnistumisemme perustuu vartiointipalveluidemme korkeaan laatuun ja luotettavuuteen. Kaikki vartijamme ovat saaneet sisäasiainministeriön vaatimukset täyttävän koulutuksen ja heillä on poliisin myöntämä voimassa oleva hyväksyntä vartijan tehtävissä toimimiseen.
                    <br/>
                    Tarjoamme vartijoillemme myös tehtäväkohtaista lisäkoulutusta ja perehdytystä. Kehitämme toimintaamme ja käytäntöjämme jatkuvasti varmistaaksemme, että palvelumme on ja säilyy aina laadukkaana.
                  </p>

                  <h4>Yhteistyössä kohti turvallisempaa tuleivaisuutta</h4>
                  <p>Olemme ylpeitä voidessamme työskennellä tiiviisti asiakkaidemme kanssa. Asiakkaan ongelmat ja huolet turvallisuusasioissa on meille sydänasia ja pyrimme aina tarjoamaan ratkaisuvaihtoehtoja näiden poistamiseksi. Otamme aina huomioon asiakkaidemme ainutlaatuiset turvallisuutarpeet räätälöidäksemme juuri heille sopivat vartiointi ja turvallisuus ratkaisut. Tarvittaessa otamme mukaan eri sidosryhmien ammattilaisia tai yhteistyökumppaneita oman toimintamme tueksi.</p>

                  <h4>Ammattitaitoinen ja asiakaspalvelukeskeinen henkilökunta</h4>
                  <p>
                    Laaja, alansa ammattilaisista koostuva joukkomme on meille ylpeyden aihe ja merkittävin ero muihin alan toimijoihin maassamme. Oikea henkilöstö oikeissa tehtävissä sekä selkeät tavoitteet luovat jatkuvuutta ja luottamusta asiakkaisemme keskuudessa.
                    <br/>
                    <span class="highlight">High-Class - Confidential - Safe</span> -periaatteemme heijastuvat kaikessa tekemisessämme, aina.
                  </p>

                  <h4>Valitsemalla vartiointipalvelumme, valitset vapauden</h4>
                  <p>Valitsemalla vartiointipalvelumme, valitset vapauden keskittää omat resurssisi liiketoimintasi ja päivittäistoimintasi kannalta tärkeämpien asioiden hoitamiseen. Oli kyseessä mikä tahansa toimiala mistä tahansa kokoluokasta, olemme varmistamassa, että sinä, henkilöstösi ja asiakkaasi voivat työskennellä ja asioida turvallisessa ympäristössä. Käytämme kokemustamme, asiantuntemustamme ja omistautumistamme turvallisuuteen, jotta voit huoletta keskittyä täysin siihen, mikä sinulle on tärkeää.</p>

                  <h4>Ota yhteyttä jo tänään niin katsotaan yhdessä, kuinka voimme palvella sinua parhaiten.</h4>
                </Col>
              </Row>

              <Row>
                <Col className="p-b-100 text-center">
                  <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ServicePageWhyChoose;