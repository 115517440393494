import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Accordion from 'react-bootstrap/Accordion'
// import Card from 'react-bootstrap/Card'
// import Button from 'react-bootstrap/Button'
import Banner from '../../components/Banner'
import './style.scss';

import nohoLogo from './noho-logo.png';
import npgLogo from './npg-logo.png';

class ReferencesPage extends Component {
  render() {
    return (
      <div className="page referencespage">
        <Banner>
          <h1>Referenssit</h1>
        </Banner>

        <Container>
          <Row className="text-center p-t-100 p-b-100">
            <Col span={12}>
                <h2>Referenssimme</h2>
                <p>
                Tuotamme turvallisuusalan palveluita valtakunnallisesti. Pääpaino työssämme on vartioinnissa sekä ravintola-, tilaisuus- ja tapahtumavalvonnassa. Lisäksi järjestämme koulutuksia järjestysmiehille ja vartijoille. Kauttamme saa myös yksilöllisemmin räätälöityjä turvallisuuspalveluita.
                </p>

                <Row className="referenssit text-center p-t-100">
                  <Col sm={{span: 6}} xs={{span: 12}}>
                    <a href="https://www.noho.fi/" rel="noopener noreferrer" target="_blank">
                      <img src={nohoLogo} alt="NoHo logo"/>
                      <p>NoHo</p>
                    </a>
                  </Col>
                  <Col sm={{span: 6}} xs={{span: 12}}>
                    <a href="https://npg.fi/" rel="noopener noreferrer" target="_blank">
                      <img src={npgLogo} alt="npg logo"/>
                      <p>Night People Group</p>
                    </a>
                  </Col>
                </Row>
            </Col>
          </Row>


        </Container>
      </div>
    );
  }
}

export default ReferencesPage;
