import React, { Component } from 'react';
import Banner from '../../components/Banner'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Link from 'react-router-dom/Link'
// import Button from 'react-bootstrap/Button'
import './style.scss';
import JobList from '../../JobList';

class OpenJobsPage extends Component {

  constructor(props){
    super(props)

    let state = {};

    JobList.forEach((job, index) => {
      state[`job${index}Open`] = false;
    });

    this.state = state;

    this.openJob = this.openJob.bind(this);
  }

  componentDidMount() {   
  }
  
  openJob(index) {
    this.setState({[`job${index}Open`]: !this.state[`job${index}Open`]});
  }

  render() {
    return (
      <div className="page employeepage">

        <Banner>
          <h1>Työnhakijalle</h1>
        </Banner>

        <Container id="content" className="intro p-t-50 p-b-50 text-center">
            <Row>
                <Col sm={{ span: 8, offset: 2}}>
                    <h1>Avoimet työpaikat</h1>
                </Col>
            </Row>             
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col>
              {JobList.map((job, index) => {
                return <div key={index} className="job">
                  <div className="job-title">
                    <h3>{job.title}</h3><div className="quantity">{job.quantity}</div>
                  </div>
                  {
                    this.state[`job${index}Open`] ?
                      <div className="job-description">{job.body}</div>
                      :
                      <div>
                        <div>
                          {job.leadIn}...
                        </div>
                        <p className="read-more"><a href="#" onClick={(e) => {e.preventDefault(); this.openJob(index)}}>Lue lisää  +</a></p>
                      </div>
                  }
                  <Link to={`/tyonhakijalle/tyo/${job.id}`} className="btn-blue">Hae Nyt</Link>
                </div>
              })}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default OpenJobsPage;
